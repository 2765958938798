import _ from 'lodash'

const parameterConfig = ({category, name, description, type, defaultValue, options, arrayLengthMultiplier, multipleLengthArrayLabels}) => {
    return {
        category: category || 'NO CATEGORY',
        name: name || 'EMPTY NAME',
        description: description || '',
        type: type || parameterTypes.NUMBER,
        defaultValue: _.isNil(defaultValue) ? 0 : defaultValue,
        options: _.isArray(options) && !_.isEmpty(options) ? options : null,
        arrayLengthMultiplier: arrayLengthMultiplier,
        multipleLengthArrayLabels: multipleLengthArrayLabels || null
    }
}

const profileParamterConfig = ({ name, description, type, defaultValue, options }) => {
    return parameterConfig({
        category: 'PROFILE',
        name,
        description,
        type,
        defaultValue,
        options
    })
}

const legParameterConfig = ({legNumber, name, description, type, defaultValue}) => {
    return parameterConfig({
        category: `LEG-${legNumber || '1'}`,
        name,
        description,
        type,
        defaultValue
    })
}

const symbolParameterConfig = ({legNumber, name, description, type, defaultValue, options}) => {
    return parameterConfig({
        category: `LEG-${legNumber || '1'}-SYMBOL`,
        name,
        description,
        type,
        defaultValue,
        options
    })
}

const refSymbolParameterConfig = ({name, description, type, defaultValue}) => {
    return parameterConfig({
        category: `REF-SYMBOL`,
        name,
        description,
        type,
        defaultValue
    })
}

const strategyParameterConfig = ({ strategyType, name, description, type, defaultValue, arrayLengthMultiplier, multipleLengthArrayLabels, isSharedAmongSymbols }) => {
    return Object.assign({}, parameterConfig({
        category: `STRATEGY-${strategyType}`,
        name,
        description,
        type,
        defaultValue,
        arrayLengthMultiplier,
        multipleLengthArrayLabels
    }), { strategyType, isSharedAmongSymbols: isSharedAmongSymbols || false})
}

export const ALL_PROFILE_GROUP_ID = 'all'

export const parameterTypes = {
    BOOLEAN: 'BOOLEAN',
    NUMBER: 'NUMBER',
    STRING: 'STRING',
    SYMBOL: 'SYMBOL',
    BUY_SELL_BOOLEAN_ARRAY: 'BUY_SELL_BOOLEAN_ARRAY',
    BUY_SELL_NUMBER_ARRAY: 'BUY_SELL_NUMBER_ARRAY',
    MIN_MAX_NUMBER_ARRAY: 'MIN_MAX_NUMBER_ARRAY',
    NUMBER_ARRAY_WITH_LENGTH_MUTLIPLIER: 'NUMBER_ARRAY_WITH_LENGTH_MUTLIPLIER',
    STRING_ARRAY: 'STRING_ARRAY'
}

export const profileParameters = {
    RUNNING_MODE: profileParamterConfig({
        name: 'Running Mode',
        description: '',
        type: parameterTypes.STRING,
        defaultValue: 'STANDALONE',
        options: ['STANDALONE', 'QUOTE', 'HEDGE', 'LSP_HEDGE', 'SIGNAL_STRATEGY']
    }),
    PEER_PROFILE_NAME: profileParamterConfig({
        name: 'Peer Profile Name',
        description: ``,
        type: parameterTypes.STRING,
        defaultValue: ''
    }),
    PEER_PROFILE_SERVER: profileParamterConfig({
        name: 'Peer Profile Server',
        description: ``,
        type: parameterTypes.STRING,
        defaultValue: ''
    }),
    PEER_PROFILE_USER: profileParamterConfig({
        name: 'Peer Profile User',
        description: ``,
        type: parameterTypes.STRING,
        defaultValue: ''
    }),
    LSP_HEDGE_SYMBOLS: profileParamterConfig({
        name: 'LSP Hedge Symbols',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    MARKET_DEPTH_TIMEOUT: profileParamterConfig({
        name: 'Market Depth Timeout',
        description: 'The trading system stops trading if it fails to receive market data from the exchange within this specifed continuous time period.',
        type: parameterTypes.NUMBER,
        defaultValue: 2000
    }),
    CANCEL_RESENT_TIME_INTERVAL: profileParamterConfig({
        name: 'Cancel Resent Time Interval',
        description: 'The trading system will cancel an <strong>order withdrawal</strong> and send a new request to the exchange if the original one fails to response within this specified time.',
        type: parameterTypes.NUMBER,
        defaultValue: 5000
    }),
    LOOP: profileParamterConfig({
        name: 'Loop',
        description: ``,
        type: parameterTypes.BOOLEAN,
        defaultValue: true
    }),
    SPECULATE_FILL_FROM_DEPTH_SWITCH: profileParamterConfig({
        name: 'Speculate Fill From Depth Switch',
        description: ``,
        type: parameterTypes.BOOLEAN,
        defaultValue: false
    }),
    ENABLE_SKEW: profileParamterConfig({
        name: 'Enable Skew',
        description: ``,
        type: parameterTypes.BOOLEAN,
        defaultValue: false
    }),
    SKEW: profileParamterConfig({
        name: 'Skew',
        description: ``,
        type: parameterTypes.NUMBER,
        defaultValue: 0
    }),
    SKEW_BASE_POS: profileParamterConfig({
        name: 'Skew Base Pos',
        description: ``,
        type: parameterTypes.NUMBER,
        defaultValue: 100
    }),
    SKEW_MULTI: profileParamterConfig({
        name: 'Skew Multi',
        description: ``,
        type: parameterTypes.NUMBER,
        defaultValue: 1
    }),
    SKEW_THRES_1: profileParamterConfig({
        name: 'Skew Threshold 1',
        description: ``,
        type: parameterTypes.NUMBER,
        defaultValue: 600
    }),
    SKEW_THRES_2: profileParamterConfig({
        name: 'Skew Threshold 2',
        description: ``,
        type: parameterTypes.NUMBER,
        defaultValue: 1000
    }),
    ENABLE_AUTO_SWITCH_ON: profileParamterConfig({
        name: 'Enable Auto Switch On',
        description: ``,
        type: parameterTypes.BOOLEAN,
        defaultValue: false
    }),
    ENABLE_EXPOSURE: profileParamterConfig({
        name: 'Enable Exposure',
        description: ``,
        type: parameterTypes.BOOLEAN,
        defaultValue: false
    }),
    ENABLE_VOL: profileParamterConfig({
        name: 'Enable Vol',
        description: ``,
        type: parameterTypes.BOOLEAN,
        defaultValue: false
    }),
    VOL_SKEW_UPDATE_1: profileParamterConfig({
        name: 'Vol Skew Update 1',
        description: ``,
        type: parameterTypes.NUMBER,
        defaultValue: 0
    }),
    VOL_THRESHOLD_1: profileParamterConfig({
        name: 'Vol Threshold 1',
        description: ``,
        type: parameterTypes.NUMBER,
        defaultValue: 0
    }),
    VOL_SKEW_UPDATE_2: profileParamterConfig({
        name: 'Vol Skew Update 2',
        description: ``,
        type: parameterTypes.NUMBER,
        defaultValue: 0
    }),
    VOL_THRESHOLD_2: profileParamterConfig({
        name: 'Vol Threshold 2',
        description: ``,
        type: parameterTypes.NUMBER,
        defaultValue: 0
    }),
    VOL_SKEW_UPDATE_3: profileParamterConfig({
        name: 'Vol Skew Update 3',
        description: ``,
        type: parameterTypes.NUMBER,
        defaultValue: 0
    }),
    VOL_THRESHOLD_3: profileParamterConfig({
        name: 'Vol Threshold 3',
        description: ``,
        type: parameterTypes.NUMBER,
        defaultValue: 0
    }),
    VOL_SKEW_UPDATE_4: profileParamterConfig({
        name: 'Vol Skew Update 4',
        description: ``,
        type: parameterTypes.NUMBER,
        defaultValue: 0
    }),
    VOL_THRESHOLD_4: profileParamterConfig({
        name: 'Vol Threshold 4',
        description: ``,
        type: parameterTypes.NUMBER,
        defaultValue: 0
    }),
    ENABLE_OPTION: profileParamterConfig({
        name: 'Enable Option',
        description: ``,
        type: parameterTypes.BOOLEAN,
        defaultValue: false
    }),
    VOL_SKEW: profileParamterConfig({
        name: 'Vol. Skew',
        description: ``,
        type: parameterTypes.NUMBER,
        defaultValue: 0
    }),
    THEORY_VOL: profileParamterConfig({
        name: 'Theory Vol.',
        description: ``,
        type: parameterTypes.NUMBER,
        defaultValue: 0
    }),
    VOL_INTEREST_RATE: profileParamterConfig({
        name: 'Vol. Interest Rate',
        description: ``,
        type: parameterTypes.NUMBER,
        defaultValue: 0
    }),
    ENABLE_ENET: profileParamterConfig({
        name: 'Enable Enet',
        description: `If Enet mode is enabled, on_signal will be called when signal data update.`,
        type: parameterTypes.BOOLEAN,
        defaultValue: false
    }),
    OKEXSPOT_SMART_POS_BID_ACCOUNTS: profileParamterConfig({
        name: 'OKEX SPOT Smart POS Bid Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    OKEXSPOT_SMART_POS_ASK_ACCOUNTS: profileParamterConfig({
        name: 'OKEX SPOT Smart POS Ask Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    OKEXSPOT_SMART_POS_SWITCH_INTERVAL_IN_SEC: profileParamterConfig({
        name: 'OKEX SPOT Smart POS Switch Interval In Seconds',
        description: ``,
        type: parameterTypes.NUMBER,
        defaultValue: 600
    }),
    OKEXSPOT_SMART_POS_BACKUP_ACCOUNT: profileParamterConfig({
        name: 'OKEX SPOT Smart POS Backup Account',
        description: ``,
        type: parameterTypes.STRING,
        defaultValue: 'INVALID'
    }),
    OKEXSPOT_SMART_ACCTS_ROUND_ROBIN_SWITCH: profileParamterConfig({
        name: 'OKEX SPOT Smart Accts Round Robin Switch',
        description: ``,
        type: parameterTypes.BOOLEAN,
        defaultValue: false
    }),
    OKEXSPOTMARGIN_SMART_POS_BID_ACCOUNTS: profileParamterConfig({
        name: 'OKEX MARGIN Smart POS Bid Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    OKEXSPOTMARGIN_SMART_POS_ASK_ACCOUNTS: profileParamterConfig({
        name: 'OKEX MARGIN Smart POS Ask Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    OKEXSPOTMARGIN_SMART_POS_SWITCH_INTERVAL_IN_SEC: profileParamterConfig({
        name: 'OKEX MARGIN Smart POS Switch Interval In Seconds',
        description: ``,
        type: parameterTypes.NUMBER,
        defaultValue: 600
    }),
    OKEXSPOTMARGIN_SMART_POS_BACKUP_ACCOUNT: profileParamterConfig({
        name: 'OKEX MARGIN Smart POS Backup Account',
        description: ``,
        type: parameterTypes.STRING,
        defaultValue: 'INVALID'
    }),
    OKEXCONTRACT_SMART_POS_BID_ACCOUNTS: profileParamterConfig({
        name: 'OKEX CONTRACT Smart POS Bid Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    OKEXCONTRACT_SMART_POS_ASK_ACCOUNTS: profileParamterConfig({
        name: 'OKEX CONTRACT Smart POS Ask Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    OKEXCONTRACT_SMART_POS_SWITCH_INTERVAL_IN_SEC: profileParamterConfig({
        name: 'OKEX CONTRACT Smart POS Switch Interval In Seconds',
        description: ``,
        type: parameterTypes.NUMBER,
        defaultValue: 600
    }),
    OKEXCONTRACT_SMART_POS_BACKUP_ACCOUNT: profileParamterConfig({
        name: 'OKEX CONTRACT Smart POS Backup Account',
        description: ``,
        type: parameterTypes.STRING,
        defaultValue: 'INVALID'
    }),
    OKEXCONTRACT_SMART_ACCTS_ROUND_ROBIN_SWITCH: profileParamterConfig({
        name: 'OKEX CONTRACT Smart Accts Round Robin Switch',
        description: ``,
        type: parameterTypes.BOOLEAN,
        defaultValue: false
    }),
    OKEXUSDSWAP_SMART_POS_BID_ACCOUNTS: profileParamterConfig({
        name: 'OKEX USD SWAP Smart POS Bid Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    OKEXUSDSWAP_SMART_POS_ASK_ACCOUNTS: profileParamterConfig({
        name: 'OKEX USD SWAP Smart POS Ask Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    OKEXUSDSWAP_SMART_POS_SWITCH_INTERVAL_IN_SEC: profileParamterConfig({
        name: 'OKEX USD SWAP Smart POS Switch Interval In Seconds',
        description: ``,
        type: parameterTypes.NUMBER,
        defaultValue: 600
    }),
    OKEXUSDSWAP_SMART_POS_BACKUP_ACCOUNT: profileParamterConfig({
        name: 'OKEX USD SWAP Smart POS Backup Account',
        description: ``,
        type: parameterTypes.STRING,
        defaultValue: 'INVALID'
    }),
    OKEXUSDSWAP_SMART_ACCTS_ROUND_ROBIN_SWITCH: profileParamterConfig({
        name: 'OKEX USD SWAP Smart Accts Round Robin Switch',
        description: ``,
        type: parameterTypes.BOOLEAN,
        defaultValue: false
    }),
    OKEXUSDTSWAP_SMART_POS_BID_ACCOUNTS: profileParamterConfig({
        name: 'OKEX USDT SWAP Smart POS Bid Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    OKEXUSDTSWAP_SMART_POS_ASK_ACCOUNTS: profileParamterConfig({
        name: 'OKEX USDT SWAP Smart POS Ask Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    OKEXUSDTSWAP_SMART_POS_SWITCH_INTERVAL_IN_SEC: profileParamterConfig({
        name: 'OKEX USDT SWAP Smart POS Switch Interval In Seconds',
        description: ``,
        type: parameterTypes.NUMBER,
        defaultValue: 600
    }),
    OKEXUSDTSWAP_SMART_POS_BACKUP_ACCOUNT: profileParamterConfig({
        name: 'OKEX USDT SWAP Smart POS Backup Account',
        description: ``,
        type: parameterTypes.STRING,
        defaultValue: 'INVALID'
    }),
    OKEXUSDTSWAP_SMART_ACCTS_ROUND_ROBIN_SWITCH: profileParamterConfig({
        name: 'OKEX USDT SWAP Smart Accts Round Robin Switch',
        description: ``,
        type: parameterTypes.BOOLEAN,
        defaultValue: false
    }),
    HUOBIFUT_SMART_POS_BID_ACCOUNTS: profileParamterConfig({
        name: 'HUOBI Future Smart POS Bid Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    HUOBIFUT_SMART_POS_ASK_ACCOUNTS: profileParamterConfig({
        name: 'HUOBI Future Smart POS Ask Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    HUOBIFUT_SMART_POS_SWITCH_INTERVAL_IN_SEC: profileParamterConfig({
        name: 'HUOBI Future Smart POS Switch Interval In Seconds',
        description: ``,
        type: parameterTypes.NUMBER,
        defaultValue: 600
    }),
    HUOBIFUT_SMART_POS_BACKUP_ACCOUNT: profileParamterConfig({
        name: 'HUOBI Future Smart POS Backup Account',
        description: ``,
        type: parameterTypes.STRING,
        defaultValue: 'INVALID'
    }),
    HUOBIFUTSWAP_SMART_POS_BID_ACCOUNTS: profileParamterConfig({
        name: 'HUOBIFUT SWAP Smart POS Bid Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    HUOBIFUTSWAP_SMART_POS_ASK_ACCOUNTS: profileParamterConfig({
        name: 'HUOBIFUT SWAP Smart POS Ask Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    HUOBIFUTSWAP_SMART_POS_SWITCH_INTERVAL_IN_SEC: profileParamterConfig({
        name: 'HUOBIFUT SWAP Smart POS Switch Interval In Seconds',
        description: ``,
        type: parameterTypes.NUMBER,
        defaultValue: 600
    }),
    HUOBIFUTSWAP_SMART_POS_BACKUP_ACCOUNT: profileParamterConfig({
        name: 'HUOBIFUT SWAP Smart POS Backup Account',
        description: ``,
        type: parameterTypes.STRING,
        defaultValue: 'INVALID'
    }),
    BINANCE_SMART_POS_BID_ACCOUNTS: profileParamterConfig({
        name: 'BINANCE Smart POS Bid Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    BINANCE_SMART_POS_ASK_ACCOUNTS: profileParamterConfig({
        name: 'BINANCE Smart POS Ask Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    BINANCE_SMART_POS_SWITCH_INTERVAL_IN_SEC: profileParamterConfig({
        name: 'BINANCE Smart POS Switch Interval In Seconds',
        description: ``,
        type: parameterTypes.NUMBER,
        defaultValue: 600
    }),
    BINANCE_SMART_POS_BACKUP_ACCOUNT: profileParamterConfig({
        name: 'BINANCESmart POS Backup Account',
        description: ``,
        type: parameterTypes.STRING,
        defaultValue: 'INVALID'
    }),
    BNBFUTAUSDT_SMART_POS_BID_ACCOUNTS: profileParamterConfig({
        name: 'BNBFUTA USDT Smart POS Bid Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    BNBFUTAUSDT_SMART_POS_ASK_ACCOUNTS: profileParamterConfig({
        name: 'BNBFUTA USDT Smart POS Ask Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    BNBFUTAUSDT_SMART_POS_SWITCH_INTERVAL_IN_SEC: profileParamterConfig({
        name: 'BNBFUTA USDT Smart POS Switch Interval In Seconds',
        description: ``,
        type: parameterTypes.NUMBER,
        defaultValue: 600
    }),
    BNBFUTAUSDT_SMART_POS_BACKUP_ACCOUNT: profileParamterConfig({
        name: 'BNBFUTA USDT Smart POS Backup Account',
        description: ``,
        type: parameterTypes.STRING,
        defaultValue: 'INVALID'
    }),
    BNBFUTAUSDT_SMART_ACCTS_ROUND_ROBIN_SWITCH: profileParamterConfig({
        name: 'BNBFUTA USDT Smart Accts Round Robin Switch',
        description: ``,
        type: parameterTypes.BOOLEAN,
        defaultValue: false
    }),
    BNBFUTABUSD_SMART_POS_BID_ACCOUNTS: profileParamterConfig({
        name: 'BNBFUTA BUSD Smart POS Bid Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    BNBFUTABUSD_SMART_POS_ASK_ACCOUNTS: profileParamterConfig({
        name: 'BNBFUTA BUSD Smart POS Ask Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    BNBFUTABUSD_SMART_POS_SWITCH_INTERVAL_IN_SEC: profileParamterConfig({
        name: 'BNBFUTA BUSD Smart POS Switch Interval In Seconds',
        description: ``,
        type: parameterTypes.NUMBER,
        defaultValue: 600
    }),
    BNBFUTABUSD_SMART_POS_BACKUP_ACCOUNT: profileParamterConfig({
        name: 'BNBFUTA BUSD Smart POS Backup Account',
        description: ``,
        type: parameterTypes.STRING,
        defaultValue: 'INVALID'
    }),
    BNBFUTABUSD_SMART_ACCTS_ROUND_ROBIN_SWITCH: profileParamterConfig({
        name: 'BNBFUTA BUSD Smart Accts Round Robin Switch',
        description: ``,
        type: parameterTypes.BOOLEAN,
        defaultValue: false
    }),
    BNBFUTACOIN_SMART_POS_BID_ACCOUNTS: profileParamterConfig({
        name: 'BNBFUTA COIN Smart POS Bid Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    BNBFUTACOIN_SMART_POS_ASK_ACCOUNTS: profileParamterConfig({
        name: 'BNBFUTA COIN Smart POS Ask Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    BNBFUTACOIN_SMART_POS_SWITCH_INTERVAL_IN_SEC: profileParamterConfig({
        name: 'BNBFUTA COIN Smart POS Switch Interval In Seconds',
        description: ``,
        type: parameterTypes.NUMBER,
        defaultValue: 600
    }),
    BNBFUTACOIN_SMART_POS_BACKUP_ACCOUNT: profileParamterConfig({
        name: 'BNBFUTA COIN Smart POS Backup Account',
        description: ``,
        type: parameterTypes.STRING,
        defaultValue: 'INVALID'
    }),
    BNBFUTACOIN_SMART_ACCTS_ROUND_ROBIN_SWITCH: profileParamterConfig({
        name: 'BNBFUTA COIN Smart Accts Round Robin Switch',
        description: ``,
        type: parameterTypes.BOOLEAN,
        defaultValue: false
    }),
    BNBFUTA_SMART_POS_ASK_ACCOUNTS: profileParamterConfig({
        name: 'BNBFUTA Smart POS Ask Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    BNBFUTA_SMART_POS_BID_ACCOUNTS: profileParamterConfig({
        name: 'BNBFUTA Smart POS Bid Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    BNBFUTA_SMART_POS_SWITCH_INTERVAL_IN_SEC: profileParamterConfig({
        name: 'BNBFUTA Smart POS Switch Interval In Seconds',
        description: ``,
        type: parameterTypes.NUMBER,
        defaultValue: 600
    }),
    BNBFUTA_SMART_POS_BACKUP_ACCOUNT: profileParamterConfig({
        name: 'BNBFUTA Smart POS Backup Account',
        description: ``,
        type: parameterTypes.STRING,
        defaultValue: 'INVALID'
    }),
    BITMEX_SMART_POS_ASK_ACCOUNTS: profileParamterConfig({
        name: 'BITMEX Smart POS Ask Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    BITMEX_SMART_POS_BID_ACCOUNTS: profileParamterConfig({
        name: 'BITMEX Smart POS Bid Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    BITMEX_SMART_POS_SWITCH_INTERVAL_IN_SEC: profileParamterConfig({
        name: 'BITMEX Smart POS Switch Interval In Seconds',
        description: ``,
        type: parameterTypes.NUMBER,
        defaultValue: 600
    }),
    BITMEX_SMART_POS_BACKUP_ACCOUNT: profileParamterConfig({
        name: 'BITMEX Smart POS Backup Account',
        description: ``,
        type: parameterTypes.STRING,
        defaultValue: 'INVALID'
    }),
    PHEMEX_SMART_POS_ASK_ACCOUNTS: profileParamterConfig({
        name: 'PHEMEX Smart POS Ask Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    PHEMEX_SMART_POS_BID_ACCOUNTS: profileParamterConfig({
        name: 'PHEMEX Smart POS Bid Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    PHEMEX_SMART_POS_SWITCH_INTERVAL_IN_SEC: profileParamterConfig({
        name: 'PHEMEX Smart POS Switch Interval In Seconds',
        description: ``,
        type: parameterTypes.NUMBER,
        defaultValue: 600
    }),
    PHEMEX_SMART_POS_BACKUP_ACCOUNT: profileParamterConfig({
        name: 'PHEMEX Smart POS Backup Account',
        description: ``,
        type: parameterTypes.STRING,
        defaultValue: 'INVALID'
    }),
    PHEMEXBTCCONTRACT_SMART_POS_ASK_ACCOUNTS: profileParamterConfig({
        name: 'PHEMEX BTC Contract Smart POS Ask Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    PHEMEXBTCCONTRACT_SMART_POS_BID_ACCOUNTS: profileParamterConfig({
        name: 'PHEMEX BTC Contract Smart POS Bid Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    PHEMEXBTCCONTRACT_SMART_POS_SWITCH_INTERVAL_IN_SEC: profileParamterConfig({
        name: 'PHEMEX BTC Contract Smart POS Switch Interval In Seconds',
        description: ``,
        type: parameterTypes.NUMBER,
        defaultValue: 600
    }),
    PHEMEXBTCCONTRACT_SMART_POS_BACKUP_ACCOUNT: profileParamterConfig({
        name: 'PHEMEX BTC Contract Smart POS Backup Account',
        description: ``,
        type: parameterTypes.STRING,
        defaultValue: 'INVALID'
    }),
    PHEMEXUSDCONTRACT_SMART_POS_ASK_ACCOUNTS: profileParamterConfig({
        name: 'PHEMEX USD Contract Smart POS Ask Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    PHEMEXUSDCONTRACT_SMART_POS_BID_ACCOUNTS: profileParamterConfig({
        name: 'PHEMEX USD Contract Smart POS Bid Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    PHEMEXUSDCONTRACT_SMART_POS_SWITCH_INTERVAL_IN_SEC: profileParamterConfig({
        name: 'PHEMEX USD Contract Smart POS Switch Interval In Seconds',
        description: ``,
        type: parameterTypes.NUMBER,
        defaultValue: 600
    }),
    PHEMEXUSDCONTRACT_SMART_POS_BACKUP_ACCOUNT: profileParamterConfig({
        name: 'PHEMEX USD Contract Smart POS Backup Account',
        description: ``,
        type: parameterTypes.STRING,
        defaultValue: 'INVALID'
    }),
    FTXSPOT_SMART_POS_BID_ACCOUNTS: profileParamterConfig({
        name: 'FTX SPOT Smart POS Bid Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    FTXSPOT_SMART_POS_ASK_ACCOUNTS: profileParamterConfig({
        name: 'FTX SPOT Smart POS Ask Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    FTXSPOT_SMART_POS_SWITCH_INTERVAL_IN_SEC: profileParamterConfig({
        name: 'FTX SPOT Smart POS Switch Interval In Seconds',
        description: ``,
        type: parameterTypes.NUMBER,
        defaultValue: 600
    }),
    FTXSPOT_SMART_POS_BACKUP_ACCOUNT: profileParamterConfig({
        name: 'FTX SPOT Smart POS Backup Account',
        description: ``,
        type: parameterTypes.STRING,
        defaultValue: 'INVALID'
    }),
    FTXFUT_SMART_POS_BID_ACCOUNTS: profileParamterConfig({
        name: 'FTX FUT Smart POS Bid Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    FTXFUT_SMART_POS_ASK_ACCOUNTS: profileParamterConfig({
        name: 'FTX FUT Smart POS Ask Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    FTXFUT_SMART_POS_SWITCH_INTERVAL_IN_SEC: profileParamterConfig({
        name: 'FTX FUT Smart POS Switch Interval In Seconds',
        description: ``,
        type: parameterTypes.NUMBER,
        defaultValue: 600
    }),
    FTXFUT_SMART_POS_BACKUP_ACCOUNT: profileParamterConfig({
        name: 'FTX FUT Smart POS Backup Account',
        description: ``,
        type: parameterTypes.STRING,
        defaultValue: 'INVALID'
    }),
    BYBIT_SMART_POS_ASK_ACCOUNTS: profileParamterConfig({
        name: 'BYBIT Smart POS Ask Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    BYBIT_SMART_POS_BID_ACCOUNTS: profileParamterConfig({
        name: 'BYBIT Smart POS Bid Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    BYBIT_SMART_POS_SWITCH_INTERVAL_IN_SEC: profileParamterConfig({
        name: 'BYBIT Smart POS Switch Interval In Seconds',
        description: ``,
        type: parameterTypes.NUMBER,
        defaultValue: 600
    }),
    BYBIT_SMART_POS_BACKUP_ACCOUNT: profileParamterConfig({
        name: 'BYBIT Smart POS Backup Account',
        description: ``,
        type: parameterTypes.STRING,
        defaultValue: 'INVALID'
    }),
    BYBIT_SMART_ACCTS_ROUND_ROBIN_SWITCH:  profileParamterConfig({
        name: 'BYBIT Smart Accts Round Robin Switch',
        description: ``,
        type: parameterTypes.BOOLEAN,
        defaultValue: false
    }),
    BITFINEX_SMART_POS_BID_ACCOUNTS: profileParamterConfig({
        name: 'BITFINEX Smart POS Bid Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    BITFINEX_SMART_POS_ASK_ACCOUNTS: profileParamterConfig({
        name: 'BITFINEX Smart POS Ask Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    BITFINEX_SMART_POS_SWITCH_INTERVAL_IN_SEC: profileParamterConfig({
        name: 'BITFINEX Smart POS Switch Interval In Seconds',
        description: ``,
        type: parameterTypes.NUMBER,
        defaultValue: 600
    }),
    BITFINEX_SMART_POS_BACKUP_ACCOUNT: profileParamterConfig({
        name: 'BITFINEX Smart POS Backup Account',
        description: ``,
        type: parameterTypes.STRING,
        defaultValue: 'INVALID'
    }),
    DERIBIT_SMART_POS_BID_ACCOUNTS: profileParamterConfig({
        name: 'DERIBIT Smart POS Bid Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    DERIBIT_SMART_POS_ASK_ACCOUNTS: profileParamterConfig({
        name: 'DERIBIT Smart POS Ask Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    DERIBIT_SMART_POS_SWITCH_INTERVAL_IN_SEC: profileParamterConfig({
        name: 'DERIBIT Smart POS Switch Interval In Seconds',
        description: ``,
        type: parameterTypes.NUMBER,
        defaultValue: 600
    }),
    DERIBIT_SMART_POS_BACKUP_ACCOUNT: profileParamterConfig({
        name: 'DERIBIT Smart POS Backup Account',
        description: ``,
        type: parameterTypes.STRING,
        defaultValue: 'INVALID'
    }),
    BTSEFUT_SMART_POS_BID_ACCOUNTS: profileParamterConfig({
        name: 'BTSEFUT Smart POS Bid Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    BTSEFUT_SMART_POS_ASK_ACCOUNTS: profileParamterConfig({
        name: 'BTSEFUT Smart POS Ask Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    BTSEFUT_SMART_POS_SWITCH_INTERVAL_IN_SEC: profileParamterConfig({
        name: 'BTSEFUT Smart POS Switch Interval In Seconds',
        description: ``,
        type: parameterTypes.NUMBER,
        defaultValue: 600
    }),
    BTSEFUT_SMART_POS_BACKUP_ACCOUNT: profileParamterConfig({
        name: 'BTSEFUT Smart POS Backup Account',
        description: ``,
        type: parameterTypes.STRING,
        defaultValue: 'INVALID'
    }),
    BIT_SMART_POS_BID_ACCOUNTS: profileParamterConfig({
        name: 'BIT Smart POS Bid Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    BIT_SMART_POS_ASK_ACCOUNTS: profileParamterConfig({
        name: 'BIT Smart POS Ask Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    BIT_SMART_POS_SWITCH_INTERVAL_IN_SEC: profileParamterConfig({
        name: 'BIT Smart POS Switch Interval In Seconds',
        description: ``,
        type: parameterTypes.NUMBER,
        defaultValue: 600
    }),
    BIT_SMART_POS_BACKUP_ACCOUNT: profileParamterConfig({
        name: 'BIT Smart POS Backup Account',
        description: ``,
        type: parameterTypes.STRING,
        defaultValue: 'INVALID'
    }),
    DYDX_SMART_POS_BID_ACCOUNTS: profileParamterConfig({
        name: 'DYDX Smart POS Bid Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    DYDX_SMART_POS_ASK_ACCOUNTS: profileParamterConfig({
        name: 'DYDX Smart POS Ask Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    DYDX_SMART_POS_SWITCH_INTERVAL_IN_SEC: profileParamterConfig({
        name: 'DYDX Smart POS Switch Interval In Seconds',
        description: ``,
        type: parameterTypes.NUMBER,
        defaultValue: 600
    }),
    DYDX_SMART_POS_BACKUP_ACCOUNT: profileParamterConfig({
        name: 'DYDX Smart POS Backup Account',
        description: ``,
        type: parameterTypes.STRING,
        defaultValue: 'INVALID'
    }),
    CRYPTOFUT_SMART_POS_BID_ACCOUNTS: profileParamterConfig({
        name: 'CRYPTOFUT Smart POS Bid Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    CRYPTOFUT_SMART_POS_ASK_ACCOUNTS: profileParamterConfig({
        name: 'CRYPTOFUT Smart POS Ask Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    CRYPTOFUT_SMART_POS_SWITCH_INTERVAL_IN_SEC: profileParamterConfig({
        name: 'CRYPTOFUT Smart POS Switch Interval In Seconds',
        description: ``,
        type: parameterTypes.NUMBER,
        defaultValue: 600
    }),
    CRYPTOFUT_SMART_POS_BACKUP_ACCOUNT: profileParamterConfig({
        name: 'CRYPTOFUT MARGIN Smart POS Backup Account',
        description: ``,
        type: parameterTypes.STRING,
        defaultValue: 'INVALID'
    }),
    KUCOINFUT_SMART_POS_BID_ACCOUNTS: profileParamterConfig({
        name: 'KUCOINFUT Smart POS Bid Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    KUCOINFUT_SMART_POS_ASK_ACCOUNTS: profileParamterConfig({
        name: 'KUCOINFUT Smart POS Ask Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    KUCOINFUT_SMART_POS_SWITCH_INTERVAL_IN_SEC: profileParamterConfig({
        name: 'KUCOINFUT Smart POS Switch Interval In Seconds',
        description: ``,
        type: parameterTypes.NUMBER,
        defaultValue: 600
    }),
    KUCOINFUT_SMART_POS_BACKUP_ACCOUNT: profileParamterConfig({
        name: 'KUCOINFUT Smart POS Backup Account',
        description: ``,
        type: parameterTypes.STRING,
        defaultValue: 'INVALID'
    }),
    COINFLEX_SMART_POS_BID_ACCOUNTS: profileParamterConfig({
        name: 'COINFLEX Smart POS Bid Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    COINFLEX_SMART_POS_ASK_ACCOUNTS: profileParamterConfig({
        name: 'COINFLEX Smart POS Ask Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    COINFLEX_SMART_POS_SWITCH_INTERVAL_IN_SEC: profileParamterConfig({
        name: 'COINFLEX Smart POS Switch Interval In Seconds',
        description: ``,
        type: parameterTypes.NUMBER,
        defaultValue: 600
    }),
    COINFLEX_SMART_POS_BACKUP_ACCOUNT: profileParamterConfig({
        name: 'COINFLEX Smart POS Backup Account',
        description: ``,
        type: parameterTypes.STRING,
        defaultValue: 'INVALID'
    }),
    BITGET_SMART_POS_BID_ACCOUNTS: profileParamterConfig({
        name: 'BITGET Smart POS Bid Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    BITGET_SMART_POS_ASK_ACCOUNTS: profileParamterConfig({
        name: 'BITGET Smart POS Ask Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    BITGET_SMART_POS_SWITCH_INTERVAL_IN_SEC: profileParamterConfig({
        name: 'BITGET Smart POS Switch Interval In Seconds',
        description: ``,
        type: parameterTypes.NUMBER,
        defaultValue: 600
    }),
    BITGET_SMART_POS_BACKUP_ACCOUNT: profileParamterConfig({
        name: 'BITGET Smart POS Backup Account',
        description: ``,
        type: parameterTypes.STRING,
        defaultValue: 'INVALID'
    }),
    GATE_SMART_POS_BID_ACCOUNTS: profileParamterConfig({
        name: 'GATE Smart POS Bid Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    GATE_SMART_POS_ASK_ACCOUNTS: profileParamterConfig({
        name: 'GATE Smart POS Ask Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    GATE_SMART_POS_SWITCH_INTERVAL_IN_SEC: profileParamterConfig({
        name: 'GATE Smart POS Switch Interval In Seconds',
        description: ``,
        type: parameterTypes.NUMBER,
        defaultValue: 600
    }),
    GATE_SMART_POS_BACKUP_ACCOUNT: profileParamterConfig({
        name: 'GATE Smart POS Backup Account',
        description: ``,
        type: parameterTypes.STRING,
        defaultValue: 'INVALID'
    }),
    SMART_POS_MARGINRATIO_THRESHOLD: profileParamterConfig({
        name: 'Global Margin Ratio Threshold',
        description: ``,
        type: parameterTypes.NUMBER,
        defaultValue: 0.15
    }),
    SMART_POS_CREDITLINERATIO_THRESHOLD: profileParamterConfig({
        name: 'Smart POS. Credit Line Ratio Threshold',
        description: ``,
        type: parameterTypes.NUMBER,
        defaultValue: 0.15
    }),
    DISABLE_HEDGE_ACTION: profileParamterConfig({
        name: 'Disable Hedge Action',
        description: `Once switched on, all hedge actions will be disabled. Use with caution!`,
        type: parameterTypes.BOOLEAN,
        defaultValue: false
    }),
    EXTRA_EXPOSURE: profileParamterConfig({
        name: 'Extra Exposure',
        description: '',
        type: parameterTypes.NUMBER,
        defaultValue: 0
    }),
    EXTRA_EXPOSURE_IN_NOTIONAL: profileParamterConfig({
        name: 'Extra Exposure In Notional',
        description: '',
        type: parameterTypes.BOOLEAN,
        defaultValue: false
    }),
    COG_COUNT_MODE: profileParamterConfig({
        name: 'COG Count Mode',
        description: '',
        type: parameterTypes.STRING,
        defaultValue: 'BY_EXPOSURE_CONVERGE',
        options: ['BY_EXPOSURE_CONVERGE', 'BY_ORDER']
    }),
    COG_EXPOSURE_CONVERAGE_MODE_COUNT_BEFORE_HIT: profileParamterConfig({
        name: 'COG Exposure Coverage Mode Count Before HIT',
        description: '',
        type: parameterTypes.NUMBER,
        defaultValue: 100
    }),
    ENABLE_BASE_SPREAD_EMA: profileParamterConfig({
        name: 'Enable Base Spread EMA',
        description: '',
        type: parameterTypes.BOOLEAN,
        defaultValue: false
    }),
    ENABLE_HEDGE_ONE_BY_ONE: profileParamterConfig({
        name: 'Enable Hedge One By One',
        description: '',
        type: parameterTypes.BOOLEAN,
        defaultValue: false
    }),
    ENABLE_MD_DELAY_CANCEL: profileParamterConfig({
        name: 'Enable MD Delay Cancel',
        description: '',
        type: parameterTypes.BOOLEAN,
        defaultValue: false
    }),
    QUOTE_SUB_SIGNAL_AS_WELL: profileParamterConfig({
        name: 'Quote Sub Signal as well',
        description: ``,
        type: parameterTypes.BOOLEAN,
        defaultValue: false
    }),
    SINGLE_SIDE_VOL_ADJUST_THRESHOLD: profileParamterConfig({
        name: 'Single Side VOL Adjust Threshold',
        description: ``,
        type: parameterTypes.NUMBER,
        defaultValue: 0
    }),
    ENABLE_PIQ: profileParamterConfig({
        name: 'Enable PIQ',
        description: ``,
        type: parameterTypes.BOOLEAN,
        defaultValue: false
    }),
    ENABLE_ON_TRADE_MD: profileParamterConfig({
        name: 'Enable On Trade MD',
        description: ``,
        type: parameterTypes.BOOLEAN,
        defaultValue: false
    }),
    ENABLE_SPECULATE: profileParamterConfig({
        name: 'Enable Speculate',
        description: `Enable fill speculation from md for BNBFUTA`,
        type: parameterTypes.BOOLEAN,
        defaultValue: false
    }),
    ENABLE_MD_TIMEOUT_CANCEL: profileParamterConfig({
        name: 'Enable MD Timeout Cancel',
        description: ``,
        type: parameterTypes.BOOLEAN,
        defaultValue: false
    }),
    SHARING_REALIZED_EXPO_NV_GROUP_NAME: profileParamterConfig({
        name: 'Sharing Realized Expo. NV Group Name',
        description: ``,
        type: parameterTypes.STRING,
        defaultValue: false
    }),
    SHARING_REALIZED_EXPO_NV_GROUP_THRESHOLD: profileParamterConfig({
        name: 'Sharing Realized Expo. NV Threshold',
        description: ``,
        type: parameterTypes.NUMBER,
        defaultValue: 0
    }),
    CHAIN_DATA_SUB: profileParamterConfig({
        name: 'Chain Data Sub',
        description: ``,
        type: parameterTypes.BOOLEAN,
        defaultValue: false
    }),
    CHAIN_DATA_LOOP: profileParamterConfig({
        name: 'Chain Data Loop',
        description: ``,
        type: parameterTypes.BOOLEAN,
        defaultValue: false
    }),
    CHAIN_DATA_TYPE: profileParamterConfig({
        name: 'Chain Data Type',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    CHAIN_DATA_CHAIN: profileParamterConfig({
        name: 'Chain Data Chain',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    CHAIN_CEX_FLOW_EXCH: profileParamterConfig({
        name: 'CEX Flow Exch',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    CHAIN_CEX_FLOW_TOKEN: profileParamterConfig({
        name: 'CEX Flow Token',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    ENABLE_MOMENTUM: profileParamterConfig({
        name: 'Enable Momentum',
        description: ``,
        type: parameterTypes.BOOLEAN,
        defaultValue: false
    }),
    MOMENTUM_WINDOW_SIZE: profileParamterConfig({
        name: 'Momentum Window Size',
        description: ``,
        type: parameterTypes.NUMBER,
        defaultValue: 20
    }),
    MOMENTUM_THRESHOLD_1: profileParamterConfig({
        name: 'Momentum Threshold 1',
        description: ``,
        type: parameterTypes.NUMBER,
        defaultValue: 0
    }),
    MOMENTUM_WIDENING_1: profileParamterConfig({
        name: 'Momentum Widening 1',
        description: ``,
        type: parameterTypes.NUMBER,
        defaultValue: 0
    }),
    MOMENTUM_TIME_SPAN_LIMIT_MS: profileParamterConfig({
        name: 'Momentum Time Span Limit(ms)',
        description: ``,
        type: parameterTypes.NUMBER,
        defaultValue: 0
    }),
    MOMENTUM_NARROWING_1: profileParamterConfig({
        name: 'Momentum Narrowing 1',
        description: ``,
        type: parameterTypes.NUMBER,
        defaultValue: 0
    }),
    ENABLE_BBO_DEPTH_MD_COMBIND: profileParamterConfig({
        name: 'Enable BBO Depth MD Combined',
        description: ``,
        type: parameterTypes.BOOLEAN,
        defaultValue: true
    })
}

export const legParameters = {
    leg1: {
        QUOTE_MAX_EXPOSURE: legParameterConfig({
            legNumber: 1,
            name: 'Quote Max Exposure',
            description: `The trading system stops trading <strong>Quote Symbols</strong> if <strong>Exposure</strong> is larger than this specified value.`,
            type: parameterTypes.NUMBER,
            defaultValue: 2.8
        }),
        QUOTE_CANCEL_EXPOSURE: legParameterConfig({
            legNumber: 1,
            name: 'Quote Cancel Exposure',
            description: `Cancel quote orders when realized net value of Quote and Hedge is equal to or larger than this specified value`,
            type: parameterTypes.NUMBER,
            defaultValue: 10000000000
        }),
        QUOTE_DYNAMIC_MAX_EXPOSURE_MULTIPLIER_BY_NEW_ORDER_QUANTITY: legParameterConfig({
            legNumber: 1,
            name: 'New Order Quantity Multiplier (Dynamic Max Exposure)',
            description: `When the value of this parameter is greater than 0, Quote Max Exposure will be ignored.`,
            type: parameterTypes.NUMBER,
            defaultValue: 0
        }),
        QUOTE_MAX_PARALLEL_LEGS: legParameterConfig({
            legNumber: 1,
            name: 'Max Parallel Legs',
            description: ``,
            type: parameterTypes.NUMBER,
            defaultValue: 0
        }),
        QUOTE_SPREAD_FISHING: legParameterConfig({
            legNumber: 1,
            name: 'Spread Fishing',
            description: ``,
            type: parameterTypes.BOOLEAN,
            defaultValue: false
        }),
        QUOTE_SPREAD_THRESHOLD_RESTRICT_RELIEVED: legParameterConfig({
            legNumber: 1,
            name: 'Spread Threshold Restrict Relieved',
            description: ``,
            type: parameterTypes.BOOLEAN,
            defaultValue: true
        }),
        QUOTE_MD_LAG_SPREAD_ADJUST_THRES_1: legParameterConfig({
            legNumber: 1,
            name: 'Quote MD Lag Spread Adjust Thres 1',
            description: `When the market data lag on the quote leg exceeds this specified value in microseconds, the spread of the quote leg will be adjusted wider by the value as specified in the "Quote MD Lag Spread Adjust Update 1".`,
            type: parameterTypes.NUMBER,
            defaultValue: 0
        }),
        QUOTE_MD_LAG_SPREAD_ADJUST_UPDATE_1: legParameterConfig({
            legNumber: 1,
            name: 'Quote MD Lag Spread Adjust Update 1',
            description: ``,
            type: parameterTypes.NUMBER,
            defaultValue: 0
        }),
        HEDGE_MD_LAG_SPREAD_ADJUST_THRES_1: legParameterConfig({
            legNumber: 1,
            name: 'Hedge MD Lag Spread Adjusted Thres 1',
            description: `For every hedge leg with weight > 0, if the market data lag exceeds this specified value in microseconds, the spread of the quote leg will be adjusted wider by the value as specified in the "Hedge MD Lag Spread Adjust Update 1".`,
            type: parameterTypes.NUMBER,
            defaultValue: 0
        }),
        HEDGE_MD_LAG_SPREAD_ADJUST_UPDATE_1: legParameterConfig({
            legNumber: 1,
            name: 'Hedge MD Lag Spread Adjust Update 1',
            description: ``,
            type: parameterTypes.NUMBER,
            defaultValue: 0
        }),
        ENABLE_ON_TRADE_MD: legParameterConfig({
            legNumber: 1,
            name: 'Enable On Trade MD',
            description: ``,
            type: parameterTypes.BOOLEAN,
            defaultValue: false
        })
    },
    leg2: {
        HEDGE_EXPOSURE_THRESHOLD: legParameterConfig({
            legNumber: 2,
            name: 'Hedge Exposure Threshold',
            description: `The trading system trades <strong>Hedge Symbols</strong> if <strong>Exposure</strong> is larger than this specified value.`,
            type: parameterTypes.NUMBER,
            defaultValue: 0.01
        }),
        HEDGE_OPPOSITE_EXPOSURE_CANCEL_RATIO: legParameterConfig({
            legNumber: 2,
            name: 'Opposite Exposure Cancel Ratio',
            description: ``,
            type: parameterTypes.NUMBER,
            defaultValue: 1.0
        }),
        HEDGE_REQUIRED_QUANTITY_RATIO_TO_QUOTE_QUANTITY: legParameterConfig({
            legNumber: 2,
            name: 'Required Qty Ratio to Quote Qty',
            description: ``,
            type: parameterTypes.NUMBER,
            defaultValue: 0
        })
    }
}

export const symbolParameters = {
    leg1: {
        LEG1_USE_INHOUSE_FUNDING_RATE: symbolParameterConfig({
            legNumber: 1,
            name: 'Use In-house Funding Rate',
            description: `When turned on for symbols with a calculated in-house funding rate, 
                the algorithm uses the time-weighted in-house funding rate. 
                In this case, the funding adjust factor and base minutes are no longer effective, 
                but the funding adjust multiplier remains effective. `,
            type: parameterTypes.BOOLEAN,
            defaultValue: false
        }),
        LEG1_FX_PROD: symbolParameterConfig({
            legNumber: 1,
            name: 'FX Symbol',
            description: ``,
            type: parameterTypes.SYMBOL,
            defaultValue: 'INVALID'
        }),
        QUOTE_FX_ADJUST_FACTOR: symbolParameterConfig({
            legNumber: 1,
            name: 'FX Adjust Factor',
            description: ``,
            type: parameterTypes.BUY_SELL_NUMBER_ARRAY,
            defaultValue: [0, 0]
        }),
        QUOTE_FX_ADJUST_DAMP: symbolParameterConfig({
            legNumber: 1,
            name: 'FX Adjust DAMP',
            description: ``,
            type: parameterTypes.NUMBER,
            defaultValue: 0
        }),
        LEG1_PROD_INITIAL_LONG_POS: symbolParameterConfig({
            legNumber: 1,
            name: 'Initial Long Position',
            type: parameterTypes.NUMBER,
            defaultValue: 0
        }),
        LEG1_PROD_INITIAL_SHORT_POS: symbolParameterConfig({
            legNumber: 1,
            name: 'Initial Short Position',
            type: parameterTypes.NUMBER,
            defaultValue: 0
        }),
        QUOTE_POST_ONLY_ENABLED: symbolParameterConfig({
            legNumber: 1,
            name: 'Post Only',
            description: `<strong>Post-only</strong> limit order ensures the order will be added to the order book and not match with a pre-existing order. 
                If your order would cause a match with a pre-existing order, your post-only limit order will be cancelled. It ensures that you will pay the <strong>marker fee</strong> and not the taker fee unless matched with a hidden order.`,
            type: parameterTypes.BOOLEAN,
            defaultValue: true
        }),
        QUOTE_IOC_ENABLED: symbolParameterConfig({
            legNumber: 1,
            name: 'IOC',
            description: ``,
            type: parameterTypes.BOOLEAN,
            defaultValue: false
        }),
        QUOTE_MARGIN_TRADE_ENABLED: symbolParameterConfig({
            legNumber: 1,
            name: 'Margin Trade',
            description: `If it is enabled, you will use borrowed funds to trade the Quote Instrument`,
            type: parameterTypes.BUY_SELL_BOOLEAN_ARRAY,
            defaultValue: [false, false]
        }),
        QUOTE_CROSS_MARGIN_TRADE_ENABLED: symbolParameterConfig({
            legNumber: 1,
            name: 'Cross Margin Trade',
            description: ``,
            type: parameterTypes.BUY_SELL_BOOLEAN_ARRAY,
            defaultValue: [false, false]
        }),
        QUOTE_LEVERAGE: symbolParameterConfig({
            legNumber: 1,
            name: 'Leverage',
            description: ``,
            type: parameterTypes.NUMBER,
            defaultValue: 0
        }),
        QUOTE_TRADE_IN_NOTIONAL_VALUE: symbolParameterConfig({
            legNumber: 1,
            name: 'Trade In Notional Value',
            description: ``,
            type: parameterTypes.BOOLEAN,
            defaultValue: false
        }),
        QUOTE_REF_SPREAD_THRESHOLD_TYPE: symbolParameterConfig({
            legNumber: 1,
            name: 'Ref Spread Threshold Type',
            description: ``,
            type: parameterTypes.STRING,
            defaultValue: 'DIFF',
            options: ['DIFF', 'RATIO', 'PREMIUM']
        }),
        QUOTE_REF_ALL_CROSS: symbolParameterConfig({
            legNumber: 1,
            name: 'Ref All Cross',
            description: ``,
            type: parameterTypes.BOOLEAN,
            defaultValue: true
        }),
        QUOTE_REF_TO_HEDGE: symbolParameterConfig({
            legNumber: 1,
            name: 'Ref To Hedge',
            description: ``,
            type: parameterTypes.BOOLEAN,
            defaultValue: true
        }),
        // QUOTE_REF_SPREAD_THRESHOLD: symbolParameterConfig({
        //     legNumber: 1,
        //     name: 'Ref Spread Threshold',
        //     description: `If <strong>Ref Instrument</strong> is provided, you buy/sell Quote Instrument only when <strong>Ref Spread</strong> is below/above this specified <strong>Buy/Sell Ref Spread Threshold</strong>. 
        //         Ref Spread = Ref Instrument Last Price - Hedge Instrument Price`,
        //     type: parameterTypes.BUY_SELL_NUMBER_ARRAY,
        //     defaultValue: [1.0, 1.5]
        // }),
        // QUOTE_TRADABLE_REF_PRICE_USE_AWAY_PRICE: symbolParameterConfig({
        //     legNumber: 1,
        //     name: 'Tradable Ref Price Use Away Price',
        //     description: `This parameter becomes effective only when the selected Ref Symbol is tradable. In the case of Buy Side, if this specified value is TRUE, Ref Spread = Ref Instrument Ask - Hedge Instrument Bid; otherwise, Ref Spread = Ref Instrument Bid - Hedge Instrument Bid`,
        //     type: parameterTypes.BUY_SELL_BOOLEAN_ARRAY,
        //     defaultValue: [true, true]
        // }),
        QUOTE_SPREAD_THRESHOLD_TYPE: symbolParameterConfig({
            legNumber: 1,
            name: 'Spread Threshold Type',
            description: ``,
            type: parameterTypes.STRING,
            defaultValue: 'DIFF',
            options: ['DIFF', 'RATIO', 'PREMIUM']
        }),
        QUOTE_SPREAD_THRESHOLD: symbolParameterConfig({
            legNumber: 1,
            name: 'Spread Threshold',
            description: `You buy/sell <strong>Quote Instrument</strong> only when <strong>Spread</strong> is below/above this specified <strong>Spread Threshold</strong>. Spread = Quote Instrument Price - Hedge Instrument Price`,
            type: parameterTypes.BUY_SELL_NUMBER_ARRAY,
            defaultValue: [1.0, 1.5]
        }),
        QUOTE_SPREAD_THRESHOLD_REQUIRED_CROSS_MS: symbolParameterConfig({
            legNumber: 1,
            name: 'Spread Threshold Required Cross MS',
            description: `Orders are sent only when the spread remains crossed for more than this specified period of time in milliseconds`,
            type: parameterTypes.NUMBER,
            defaultValue: 0
        }),
        QUOTE_SPREAD_THRESHOLD_BOUND: symbolParameterConfig({
            legNumber: 1,
            name: 'Spread Threshold Bound',
            description: ``,
            type: parameterTypes.BUY_SELL_NUMBER_ARRAY,
            defaultValue: [100000, -100000]
        }),
        QUOTE_SPREAD_THRESHOLD_ADJUST_BASE_POS: symbolParameterConfig({
            legNumber: 1,
            name: 'Spread Threshold Adjust Base Pos',
            description: ``,
            type: parameterTypes.NUMBER,
            defaultValue: 0
        }),
        QUOTE_SPREAD_THRESHOLD_ADJUST_MULTIPLIER: symbolParameterConfig({
            legNumber: 1,
            name: 'Spread Threshold Adjust Multiplier',
            description: ``,
            type: parameterTypes.BUY_SELL_NUMBER_ARRAY,
            defaultValue: [1, 1]
        }),
        QUOTE_SPREAD_THRESHOLD_ADJUST_BY_FUNDING_SWITCH: symbolParameterConfig({
            legNumber: 1,
            name: 'Spread Threshold Adjust by Funding Switch',
            description: ``,
            type: parameterTypes.BOOLEAN,
            defaultValue: true
        }),
        QUOTE_SPREAD_FUNDING_RATE_ADJUST_METHOD: symbolParameterConfig({
            legNumber: 1,
            name: 'Spread Funding Rate Adjust Method',
            description: ``,
            type: parameterTypes.STRING,
            options: ['NORMAL', 'INVERSE'],
            defaultValue: 'NORMAL'
        }),
        QUOTE_SPREAD_FUNDING_RATE_ADJUST_FACTOR: symbolParameterConfig({
            legNumber: 1,
            name: 'Spread Funding Rate Adjust Factor',
            description: ``,
            type: parameterTypes.NUMBER,
            defaultValue: 0.001
        }),
        QUOTE_SPREAD_FUNDING_RATE_ADJUST_FACTOR_BASE_MINUTES: symbolParameterConfig({
            legNumber: 1,
            name: 'Spread Funding Rate Adjust Factor Base Minutes',
            description: ``,
            type: parameterTypes.NUMBER,
            defaultValue: 0
        }),
        QUOTE_SPREAD_FUNDING_RATE_ADJUST_MULTIPLIER: symbolParameterConfig({
            legNumber: 1,
            name: 'Spread Funding Rate Adjust Multiplier',
            description: ``,
            type: parameterTypes.NUMBER,
            defaultValue: 0
        }),
        QUOTE_MAX_SIDE_POS: symbolParameterConfig({
            legNumber: 1,
            name: 'Max Side Position',
            description: `Orders will be sent only when <strong>Virtual Position</strong> is within this specified <strong>Max Side Position</strong>`,
            type: parameterTypes.BUY_SELL_NUMBER_ARRAY,
            defaultValue: [0.0, 0.0]
        }),
        QUOTE_NEW_ORDER_QTY: symbolParameterConfig({
            legNumber: 1,
            name: 'New Order Qty',
            description: `New Order Quantity`,
            type: parameterTypes.BUY_SELL_NUMBER_ARRAY,
            defaultValue: [2.0, 2.0]
        }),
        QUOTE_NEW_ORDER_QTY_RANDOM_DELTA: symbolParameterConfig({
            legNumber: 1,
            name: 'New Order Qty Random Delta',
            description: ``,
            type: parameterTypes.MIN_MAX_NUMBER_ARRAY,
            defaultValue: [0, 0]
        }),
        QUOTE_THEORY_BASE_RATIO: symbolParameterConfig({
            legNumber: 1,
            name: 'Theory Base Ratio',
            description: ``,
            type: parameterTypes.NUMBER,
            defaultValue: 1.0
        }),
        QUOTE_EXPOSURE_WEIGHT: symbolParameterConfig({
            legNumber: 1,
            name: 'Exposure Weight',
            description: `It is the weight of current <strong>Quote Instrument's Position</strong> when <strong>Exposure</strong> is calculated. Exposue = Σ(Weight * Quote Instrument's Position) + Hedge Instrument's Position.`,
            type: parameterTypes.NUMBER,
            defaultValue: 1.0
        }),
        QUOTE_PRICE_CHANGE_LIMIT_MS_INTERVAL: symbolParameterConfig({
            legNumber: 1,
            name: 'Price Change Limit MS Interval',
            description: `Stop sending orders if the price's fluctuation exceeds <strong>Price Change Limit Threshold</strong> within this specified time window.`,
            type: parameterTypes.NUMBER,
            defaultValue: 0
        }),
        QUOTE_PRICE_CAHNGE_LIMIT_THRESHOLD: symbolParameterConfig({
            legNumber: 1,
            name: 'Price Change Limit Threshold',
            description: `Stop sending orders if the price's fluctuation exceeds this specified threshold within <strong>Price Change Limit MS Interval</strong>`,
            type: parameterTypes.NUMBER,
            defaultValue: 0
        }),
        QUOTE_SYMBOL_MAX_POS_SWITCH: symbolParameterConfig({
            legNumber: 1,
            name: 'Symbol Max Pos Switch',
            description: ``,
            type: parameterTypes.BOOLEAN,
            defaultValue: false
        }),
        QUOTE_SYMBOL_MAX_POS_THRESHOLD: symbolParameterConfig({
            legNumber: 1,
            name: 'Symbol Max Pos Threshold',
            description: ``,
            type: parameterTypes.BUY_SELL_NUMBER_ARRAY,
            defaultValue: [0, 0]
        }),
        QUOTE_MMP_ENABLED: symbolParameterConfig({
            legNumber: 1,
            name: 'MMP Enabled',
            description: ``,
            type: parameterTypes.BOOLEAN,
            defaultValue: true
        }),
        QUOTE_AUTO_BORROW_ENABLED: symbolParameterConfig({
            legNumber: 1,
            name: 'Auto Borrow',
            description: ``,
            type: parameterTypes.BUY_SELL_BOOLEAN_ARRAY,
            defaultValue: [false, false]
        }),
        QUOTE_ENET_MKT_SWITCH_MID_WEIGHT: symbolParameterConfig({
            legNumber: 1,
            name: 'MKT Switch MID Weight',
            description: `The weight of market switch mid price is used to calculate model theory price. The sum of weight should be equal to 1.`,
            type: parameterTypes.NUMBER,
            defaultValue: 0
        }),
        QUOTE_ENET_BURST_TRADE_PX_WEIGHT: symbolParameterConfig({
            legNumber: 1,
            name: 'Burst Trade PX Weight',
            description: `The weight of burst trade price is used to calculate model theory price. The sum of weight should be equal to 1.`,
            type: parameterTypes.NUMBER,
            defaultValue: 1
        }),
        QUOTE_ENET_VOL_RATIO_MULTIPLIER: symbolParameterConfig({
            legNumber: 1,
            name: 'VOL Ratio Multiplier',
            description: `The volume ratio multiplier is used to dynamically adjust the quote spread.`,
            type: parameterTypes.NUMBER,
            defaultValue: 6
        }),
        QUOTE_ENET_SAFE_NEW_FLAG: symbolParameterConfig({
            legNumber: 1,
            name: 'Safe New Flag',
            description: `If the safe new flag is true, the theory price will be capped by the hedge price before new order.`,
            type: parameterTypes.BOOLEAN,
            defaultValue: true
        }),
        QUOTE_MARKET_DEPTH_TIMEOUT: symbolParameterConfig({
            legNumber: 1,
            name: 'Market Depth',
            description: ``,
            type: parameterTypes.NUMBER,
            defaultValue: 10000
        }),
        QUOTE_SIGNAL_WEIGHT: symbolParameterConfig({
            legNumber: 1,
            name: 'Signal Weight',
            description: ``,
            type: parameterTypes.NUMBER,
            defaultValue: 0
        }),
        QUOTE_MD_DELAY_THRESHOLD: symbolParameterConfig({
            legNumber: 1,
            name: 'MD Delay Threshold',
            description: ``,
            type: parameterTypes.NUMBER,
            defaultValue: 200000
        }),
        QUOTE_MD_DELAY_RESET_THRESHOLD: symbolParameterConfig({
            legNumber: 1,
            name: 'MD Delay Reset Threshold',
            description: ``,
            type: parameterTypes.NUMBER,
            defaultValue: 20000
        }),
        QUOTE_USE_20L_SNAPSHOT: symbolParameterConfig({
            legNumber: 1,
            name: 'Use 20L Snapshot',
            description: ``,
            type: parameterTypes.BOOLEAN,
            defaultValue: false
        }),
        QUOTE_MOMENTUM_CALCULATION_ENABLED: symbolParameterConfig({
            legNumber: 1,
            name: 'Momentum Calculation Enabled',
            description: ``,
            type: parameterTypes.BOOLEAN,
            defaultValue: false
        }),
        QUOTE_MAKER_FEE_RATE: symbolParameterConfig({
            legNumber: 1,
            name: 'Maker Fee Rate',
            description: ``,
            type: parameterTypes.NUMBER,
            defaultValue: 0
        }),
        QUOTE_TAKER_FEE_RATE: symbolParameterConfig({
            legNumber: 1,
            name: 'Taker Fee Rate',
            description: ``,
            type: parameterTypes.NUMBER,
            defaultValue: 0
        }),
        QUOTE_SYMBOL_SUB_BBO: symbolParameterConfig({
            legNumber: 1,
            name: 'Symbol Sub BOO',
            description: ``,
            type: parameterTypes.BOOLEAN,
            defaultValue: false
        })
    },
    leg2: {
        LEG2_USE_INHOUSE_FUNDING_RATE: symbolParameterConfig({
            legNumber: 2,
            name: 'Use In-house Funding Rate',
            description: `When turned on for symbols with a calculated in-house funding rate, 
                the algorithm uses the time-weighted in-house funding rate. 
                In this case, the funding adjust factor and base minutes are no longer effective, 
                but the funding adjust multiplier remains effective. `,
            type: parameterTypes.BOOLEAN,
            defaultValue: false
        }),
        LEG2_PROD_INITIAL_LONG_POS: symbolParameterConfig({
            legNumber: 1,
            name: 'Initial Long Position',
            defaultValue: 0
        }),
        LEG2_PROD_INITIAL_SHORT_POS: symbolParameterConfig({
            legNumber: 1,
            name: 'Initial Short Position',
            defaultValue: 0
        }),
        HEDGE_POST_ONLY_ENABLED: symbolParameterConfig({
            legNumber: 2,
            name: 'Post Only',
            description: `<strong>Post-only</strong> limit order ensures the order will be added to the order book and not match with a pre-existing order. 
                If your order would cause a match with a pre-existing order, your post-only limit order will be cancelled. It ensures that you will pay the <strong>marker fee</strong> and not the taker fee unless matched with a hidden order.`,
            type: parameterTypes.BOOLEAN,
            defaultValue: false
        }),
        HEDGE_IOC_ENABLED: symbolParameterConfig({
            legNumber: 2,
            name: 'IOC',
            description: ``,
            type: parameterTypes.BOOLEAN,
            defaultValue: false
        }),
        HEDGE_MARGIN_TRADE_ENABLED: symbolParameterConfig({
            legNumber: 2,
            name: 'Margin Trade',
            description: `If it is enabled, you will use borrowed funds to trade the Hedge Instrument.`,
            type: parameterTypes.BUY_SELL_BOOLEAN_ARRAY,
            defaultValue: [false, false]
        }),
        HEDGE_CROSS_MARGIN_TRADE_ENABLED: symbolParameterConfig({
            legNumber: 2,
            name: 'Cross Margin Trade',
            description: ``,
            type: parameterTypes.BUY_SELL_BOOLEAN_ARRAY,
            defaultValue: [false, false]
        }),
        HEDGE_LEVERAGE: symbolParameterConfig({
            legNumber: 2,
            name: 'Leverage',
            description: ``,
            type: parameterTypes.NUMBER,
            defaultValue: 0
        }),
        HEDGE_TRADE_IN_NOTIONAL_VALUE: symbolParameterConfig({
            legNumber: 2,
            name: 'Trade In Notional Value',
            description: ``,
            type: parameterTypes.BOOLEAN,
            defaultValue: false
        }),
        HEDGE_SPREAD_THRESHOLD_ADJUST_BY_FUNDING_SWITCH: symbolParameterConfig({
            legNumber: 2,
            name: 'Spread Threshold Adjust by Funding Switch',
            description: ``,
            type: parameterTypes.BOOLEAN,
            defaultValue: true
        }),
        HEDGE_SPREAD_FUNDING_RATE_ADJUST_METHOD: symbolParameterConfig({
            legNumber: 1,
            name: 'Spread Funding Rate Adjust Method',
            description: ``,
            type: parameterTypes.STRING,
            options: ['NORMAL', 'INVERSE'],
            defaultValue: 'NORMAL'
        }),
        HEDGE_SPREAD_FUNDING_RATE_ADJUST_FACTOR: symbolParameterConfig({
            legNumber: 2,
            name: 'Spread Funding Rate Adjust Factor',
            description: ``,
            type: parameterTypes.NUMBER,
            defaultValue: 0.001
        }),
        HEDGE_SPREAD_FUNDING_RATE_ADJUST_FACTOR_BASE_MINUTES: symbolParameterConfig({
            legNumber: 2,
            name: 'Spread Funding Rate Adjust Factor Base Minutes',
            description: ``,
            type: parameterTypes.NUMBER,
            defaultValue: 0
        }),
        HEDGE_SPREAD_FUNDING_RATE_ADJUST_MULTIPLIER: symbolParameterConfig({
            legNumber: 2,
            name: 'Spread Funding Rate Adjust Multiplier',
            description: ``,
            type: parameterTypes.NUMBER,
            defaultValue: 0
        }),
        HEDGE_BIG_PRICE_WEIGHT: symbolParameterConfig({
            legNumber: 2,
            name: 'BIG Price Weight',
            description: `It is effective and BIG is applied only when you selected more than one hedge instruments. It is the weight of current Hedge Instrument when <strong>Spread</strong> and <strong>Theoretical Price</strong> are calcualted. 
                Spread = Quote Instrument Price - Σ(Weight * Hedge Instrument Price). Theoretical Price = Σ(Weight * Hedge Instrument Price) + Spread Threshold`,
            type: parameterTypes.NUMBER,
            defaultValue: 1.0
        }),
        HEDGE_BIG_FAIR_PRICE_USE_AWAY_PRICE: symbolParameterConfig({
            legNumber: 2,
            name: 'BIG Fair Price Use Away Price',
            description: '',
            type: parameterTypes.BUY_SELL_BOOLEAN_ARRAY,
            defaultValue: [true, true]
        }),
        HEDGE_BIG_FAIR_PRICE_OFFSET: symbolParameterConfig({
            legNumber: 2,
            name: 'BIG Fair Price Offset',
            description: `When BIG is applied, the Hedge Instrument with best <strong>Fair Price</strong> will be selected to trade. Current Hedge Instrument's <strong>Fair Price</strong> = Hedge Instrument Price + this specified Offset`,
            type: parameterTypes.BUY_SELL_NUMBER_ARRAY,
            defaultValue: [0, 0]
        }),
        HEDGE_BIG_MAX_ORDER_NOTIONAL: symbolParameterConfig({
            legNumber: 2,
            name: 'BIG Max Order Notional',
            description: `It defines the maximum order notional value of current Hedge Instrument when BIG is applied.`,
            type: parameterTypes.NUMBER,
            defaultValue: 100000
        }),
        HEDGE_BIG_FAIR_PRICE_OFFSET_TYPE: symbolParameterConfig({
            legNumber: 2,
            name: 'BIG Fair Price Offset Type',
            description: ``,
            type: parameterTypes.STRING,
            defaultValue: 'DIFF',
            options: ['DIFF', 'RATIO', 'PREMIUM']
        }),
        HEDGE_BIG_FAIR_PRICE_OFFSET_FUNDING: symbolParameterConfig({
            legNumber: 2,
            name: 'BIG Fair Price Offset Funding',
            description: ``,
            type: parameterTypes.BUY_SELL_BOOLEAN_ARRAY,
            defaultValue: [true, true]
        }),
        HEDGE_BIG_OB_VOLUME: symbolParameterConfig({
            legNumber: 2,
            name: 'BIG OB Volume',
            description: ``,
            type: parameterTypes.NUMBER,
            defaultValue: 1
        }),
        HEDGE_AUTO_BORROW_ENABLED: symbolParameterConfig({
            legNumber: 1,
            name: 'Auto Borrow',
            description: ``,
            type: parameterTypes.BUY_SELL_BOOLEAN_ARRAY,
            defaultValue: [false, false]
        }),
        HEDGE_MARKET_DEPTH_TIMEOUT: symbolParameterConfig({
            legNumber: 1,
            name: 'Market Depth',
            description: ``,
            type: parameterTypes.NUMBER,
            defaultValue: 10000
        }),
        HEDGE_MD_DELAY_THRESHOLD: symbolParameterConfig({
            legNumber: 1,
            name: 'MD Delay Threshold',
            description: ``,
            type: parameterTypes.NUMBER,
            defaultValue: 200000
        }),
        HEDGE_MD_DELAY_RESET_THRESHOLD: symbolParameterConfig({
            legNumber: 1,
            name: 'MD Delay Reset Threshold',
            description: ``,
            type: parameterTypes.NUMBER,
            defaultValue: 10000
        }),
        HEDGE_USE_20L_SNAPSHOT: symbolParameterConfig({
            legNumber: 2,
            name: 'Use 20L Snapshot',
            description: ``,
            type: parameterTypes.BOOLEAN,
            defaultValue: false
        }),
        HEDGE_MAKER_FEE_RATE: symbolParameterConfig({
            legNumber: 1,
            name: 'Maker Fee Rate',
            description: ``,
            type: parameterTypes.NUMBER,
            defaultValue: 0
        }),
        HEDGE_TAKER_FEE_RATE: symbolParameterConfig({
            legNumber: 1,
            name: 'Taker Fee Rate',
            description: ``,
            type: parameterTypes.NUMBER,
            defaultValue: 0
        }),
        HEDGE_SYMBOL_SUB_BBO: symbolParameterConfig({
            legNumber: 1,
            name: 'Symbol Sub BOO',
            description: ``,
            type: parameterTypes.BOOLEAN,
            defaultValue: false
        })
    }
}

export const refSymbolParameters = {
    QUOTE_REF_SPREAD_THRESHOLD: refSymbolParameterConfig({
        name: 'Ref Spread Threshold',
        description: ``,
        type: parameterTypes.BUY_SELL_NUMBER_ARRAY,
        defaultValue: [-0.001, 0.001]
    }),
    QUOTE_TRADABLE_REF_PRICE_USE_AWAY_PRICE: refSymbolParameterConfig({
        name: 'Tradable Ref Price Use Away Price',
        description: ``,
        type: parameterTypes.BUY_SELL_BOOLEAN_ARRAY,
        defaultValue: [true, true]
    })
}

export const strategies = {
    DIME: {
        type: 'DIME',
        name: 'DIME',
        parameters: {
            DIME_TIMEOUT_SINCE_LAST_TRADE: strategyParameterConfig({
                strategyType: 'DIME',
                name: 'Timeout Since Last Trade',
                description: `A pending order will be cancelled after this specified number of milliseconds from the last trade. A new order will be sent.`,
                type: parameterTypes.NUMBER,
                defaultValue: 1000000,
                isSharedAmongSymbols: true
            }),
            DIME_MAX_MORE_AGGRESSIVE_QTY: strategyParameterConfig({
                strategyType: 'DIME',
                name: 'Max More Aggressive Qty',
                description: `A pending order will be cancelled if the total quantity of orders with higher priority is larger than this specified value. A new order will be sent.`,
                type: parameterTypes.BUY_SELL_NUMBER_ARRAY,
                defaultValue: [5.0, 5.0]
            }),
            DIME_AGGRESSIVE_TAKE_ENABLED: strategyParameterConfig({
                strategyType: 'DIME',
                name: 'Aggressive Take',
                description: `If it is enabled, <strong>Taker Orders</strong> will be sent to buy/sell Quote Instrument if <strong>Ask/Bid</strong> is below/above the <strong>Aggressive Theoretical Price</strong>.
                    The order's price should be <strong>Ask/Bid</strong> while its quantity should be Min(Market Depth of Ask/Bid, New Order Qty). 
                    <strong>Aggressive Theoretical Price</strong> is calcualted based on <strong>Aggressive Take Offset On Price Threshold</strong>.`,
                type: parameterTypes.BOOLEAN,
                defaultValue: false
            }),
            DIME_AGGRESSIVE_TAKE_TIMEOUT: strategyParameterConfig({
                strategyType: 'DIME',
                name: 'Aggressive Take Timeout',
                description: `A pending <strong>Aggressive Take Order</strong> will be cancelled if it is not fulfilled after this specified number of milliseconds.`,
                type: parameterTypes.NUMBER,
                defaultValue: 10
            }),
            DIME_AGGRESSIVE_TAKE_SPREAD_THRESHOLD_OFFSET: strategyParameterConfig({
                strategyType: 'DIME',
                name: 'Aggressive Take Spread Threshold Offset',
                description: `On the buy/sell side, <strong>Aggressive Theoretical Price</strong> = Theoretical Price -/+ this specified Offset`,
                type: parameterTypes.NUMBER,
                defaultValue: 0
            }),
            DIME_MIN_REPLACE_THRES: strategyParameterConfig({
                strategyType: 'DIME',
                name: 'Min Replace Thres',
                type: parameterTypes.NUMBER,
                defaultValue: 0
            }),
            DIME_PLACE_THRESHOLD: strategyParameterConfig({
                strategyType: 'DIME',
                name: 'Place Threshold',
                type: parameterTypes.BUY_SELL_NUMBER_ARRAY,
                defaultValue: [0.9, 1.0]
            }),
            DIME_INIT_FRONT_QTY_THRESHOLD: strategyParameterConfig({
                strategyType: 'DIME',
                name: 'Init Front Qty Threshold',
                type: parameterTypes.NUMBER,
                defaultValue: 0
            }),
            DIME_EST_FRONT_QTY_THRESHOLD: strategyParameterConfig({
                strategyType: 'DIME',
                name: 'Est Front Qty Threshold',
                type: parameterTypes.NUMBER,
                defaultValue: 0
            }),
            DIME_FRONT_END_QTY_RATIO: strategyParameterConfig({
                strategyType: 'DIME',
                name: 'Front End Qty Threshold',
                type: parameterTypes.NUMBER,
                defaultValue: 0
            }),
            DIME_ENABLE_PIQ: strategyParameterConfig({
                strategyType: 'DIME',
                name: 'Enable PIG',
                type: parameterTypes.BOOLEAN,
                defaultValue: false
            }),
            DIME_SUPPORT_NOTIONAL: strategyParameterConfig({
                strategyType: 'DIME',
                name: 'Suport Notional',
                type: parameterTypes.NUMBER,
                defaultValue: 0
            }),
            DIME_MAX_PENDING_ORDER_IN_ONE_SIDE: strategyParameterConfig({
                strategyType: 'DIME',
                name: 'Max Pending Order In One Side',
                type: parameterTypes.NUMBER,
                defaultValue: 5
            })
        }
    },
    MULTILEVEL: {
        type: 'MULTILEVEL',
        name: 'Multi-Level',
        parameters: {
            MULTILEVEL_MAX_NEW_ORDER_IN_ONE_SIDE: {
                strategyType: 'MULTILEVEL',
                name: 'Max New Order In One Side',
                description: ``,
                type: parameterTypes.NUMBER,
                defaultValue: 3
            },
            MULTILEVEL_MAX_PENDING_ORDER_IN_ONE_SIDE: {
                strategyType: 'MULTILEVEL',
                name: 'Max Pending Order In One Side',
                description: ``,
                type: parameterTypes.NUMBER,
                defaultValue: 7
            },
            MULTILEVEL_PRICE_TICK_MULTIPLIER: {
                strategyType: 'MULTILEVEL',
                name: 'Price Tick Multiplier',
                description: ``,
                type: parameterTypes.NUMBER,
                defaultValue: 1
            },
            MULTILEVEL_BASE_LEVEL: {
                strategyType: 'MULTILEVEL',
                name: 'Base Level',
                description: ``,
                type: parameterTypes.NUMBER,
                defaultValue: 1
            },
            MULTILEVEL_MAX_BASE_LEVEL: {
                strategyType: 'MULTILEVEL',
                name: 'Max Base Level',
                description: ``,
                type: parameterTypes.NUMBER,
                defaultValue: 5
            },
            MULTILEVEL_CANCEL_MAX_LEVEL: {
                strategyType: 'MULTILEVEL',
                name: 'Cancel Max Level',
                description: ``,
                type: parameterTypes.NUMBER,
                defaultValue: 5
            },
            MULTILEVEL_CANCEL_AGGRESSIVE_TICK_THAN_THEORY: {
                strategyType: 'MULTILEVEL',
                name: 'Cancel Aggressive Tick Than Theory',
                description: ``,
                type: parameterTypes.NUMBER,
                defaultValue: 1
            },
            MULTILEVEL_CANCEL_PASSIVE_TICK_THAN_THEORY: {
                strategyType: 'MULTILEVEL',
                name: 'Cancel Passive Tick Than Theory',
                description: ``,
                type: parameterTypes.NUMBER,
                defaultValue: 10000
            },
            MULTILEVEL_BASE_LEVEL_CHECK_ENABLED: {
                strategyType: 'MULTILEVEL',
                name: 'Base Level Check',
                description: ``,
                type: parameterTypes.BOOLEAN,
                defaultValue: true
            },
            MULTILEVEL_PASSIVE_CHECK_ENABLED: {
                strategyType: 'MULTILEVEL',
                name: 'Passive Check',
                description: ``,
                type: parameterTypes.BUY_SELL_BOOLEAN_ARRAY,
                defaultValue: [true, true]
            },
            MULTILEVEL_SIDE_WIDENING_SPREAD: {
                strategyType: 'MULTILEVEL',
                name: 'Side Widenig Spread',
                description: ``,
                type: parameterTypes.NUMBER,
                defaultValue: 0
            },
            MULTILEVEL_ENABLE_PARAMETER_IN_PREMIUM: {
                strategyType: 'MULTILEVEL',
                name: 'Enable Parameter in Premium',
                description: ``,
                type: parameterTypes.BOOLEAN,
                defaultValue: false
            },
            MULTILEVEL_STEP_SIZE_IN_PREMIUM: {
                strategyType: 'MULTILEVEL',
                name: 'Step Size in Premium',
                description: ``,
                type: parameterTypes.NUMBER,
                defaultValue: 0.0002
            },
            MULTILEVEL_AGGRESSIVE_BBO_THRES: {
                strategyType: 'MULTILEVEL',
                name: 'Aggressive BBO Thres.',
                description: ``,
                type: parameterTypes.NUMBER,
                defaultValue: 10000
            },
            MULTILEVEL_AGGRESSIVE_ORDER_COUNT: {
                strategyType: 'MULTILEVEL',
                name: 'Aggressive Order Count',
                description: ``,
                type: parameterTypes.NUMBER,
                defaultValue: 1
            },
            MULTILEVEL_WIDENING_SPREAD_THRES: {
                strategyType: 'MULTILEVEL',
                name: 'Widening Spread Thres.',
                description: ``,
                type: parameterTypes.NUMBER,
                defaultValue: 0.001
            },
            MULTILEVEL_WIDENING_SPREAD: {
                strategyType: 'MULTILEVEL',
                name: 'Widening Spread',
                description: ``,
                type: parameterTypes.NUMBER,
                defaultValue: 0
            }
        }
    },
    OPTMULTILEVEL: {
        type: 'OPTMULTILEVEL',
        name: 'OPTMULTILEVEL',
        parameters: {
            OPTMULTILEVEL_SIDE_WIDENING_SPREAD: strategyParameterConfig({
                strategyType: 'OPTMULTILEVEL',
                name: 'Side Widening Spread',
                description: ``,
                type: parameterTypes.NUMBER,
                defaultValue: 0
            }),
            OPTMULTILEVEL_ENABLE_PARAMETER_IN_PREMIUM: strategyParameterConfig({
                strategyType: 'OPTMULTILEVEL',
                name: 'Enable Parameter in Premium',
                description: ``,
                type: parameterTypes.BOOLEAN,
                defaultValue: false
            }),
            OPTMULTILEVEL_MAX_NEW_ORDER_IN_ONE_SIDE: strategyParameterConfig({
                strategyType: 'OPTMULTILEVEL',
                name: 'Max New Order in One Side',
                description: ``,
                type: parameterTypes.NUMBER,
                defaultValue: 3
            }),
            OPTMULTILEVEL_MAX_PENDING_ORDER_IN_ONE_SIDE: strategyParameterConfig({
                strategyType: 'OPTMULTILEVEL',
                name: 'Max Pending Order in One Side',
                description: ``,
                type: parameterTypes.NUMBER,
                defaultValue: 7
            }),
            OPTMULTILEVEL_PRICE_TICK_MULTIPLIER: strategyParameterConfig({
                strategyType: 'OPTMULTILEVEL',
                name: 'Price Tick Multiplier',
                description: ``,
                type: parameterTypes.NUMBER,
                defaultValue: 1
            }),
            OPTMULTILEVEL_STEP_SIZE_IN_PREMIUM: strategyParameterConfig({
                strategyType: 'OPTMULTILEVEL',
                name: 'Step Size in Premium',
                description: ``,
                type: parameterTypes.NUMBER,
                defaultValue: 0.0002
            }),
            OPTMULTILEVEL_BASE_LEVEL: strategyParameterConfig({
                strategyType: 'OPTMULTILEVEL',
                name: 'Base Level',
                description: ``,
                type: parameterTypes.NUMBER,
                defaultValue: 1
            }),
            OPTMULTILEVEL_MAX_BASE_LEVEL: strategyParameterConfig({
                strategyType: 'OPTMULTILEVEL',
                name: 'Max Base Level',
                description: ``,
                type: parameterTypes.NUMBER,
                defaultValue: 5
            }),
            OPTMULTILEVEL_CANCEL_MAX_LEVEL: strategyParameterConfig({
                strategyType: 'OPTMULTILEVEL',
                name: 'Cancel Max Level',
                description: ``,
                type: parameterTypes.NUMBER,
                defaultValue: 5
            }),
            OPTMULTILEVEL_CANCEL_AGGRESSIVE_TICK_THAN_THEORY: strategyParameterConfig({
                strategyType: 'OPTMULTILEVEL',
                name: 'Cancel Aggressive Tick Than Theory',
                description: ``,
                type: parameterTypes.NUMBER,
                defaultValue: 1
            }),
            OPTMULTILEVEL_CANCEL_PASSIVE_TICK_THAN_THEORY: strategyParameterConfig({
                strategyType: 'OPTMULTILEVEL',
                name: 'Cancel Passive Tick Than Theory',
                description: ``,
                type: parameterTypes.NUMBER,
                defaultValue: 10000
            }),
            OPTMULTILEVEL_BASE_LEVEL_CHECK_ENABLED: strategyParameterConfig({
                strategyType: 'OPTMULTILEVEL',
                name: 'Base Level Check Enabled',
                description: ``,
                type: parameterTypes.BOOLEAN,
                defaultValue: false
            }),
            OPTMULTILEVEL_PASSIVE_CHECK_ENABLED: strategyParameterConfig({
                strategyType: 'OPTMULTILEVEL',
                name: 'Passive Check Enabled',
                description: ``,
                type: parameterTypes.BUY_SELL_BOOLEAN_ARRAY,
                defaultValue: [true, true]
            })
        }
    },
    HIT: {
        type: 'HIT',
        name: 'HIT',
        parameters: {
            HIT_AGGRESSIVE_TICKS: strategyParameterConfig({
                strategyType: 'HIT',
                name: 'Aggressive Ticks',
                description: `When you send orders to buy/sell Hedge Instrument, the order price will be Ask/Bid +/- this specified number of ticks.`,
                type: parameterTypes.BUY_SELL_NUMBER_ARRAY,
                defaultValue: [0, 0],
                isSharedAmongSymbols: false
            }),
            HIT_STOP_TICKS: strategyParameterConfig({
                strategyType: 'HIT',
                name: 'Stop Ticks',
                description: `A pending buy/sell order will be cancelled if its order price is larger/smaller than current Ask/Bid by this specified number of ticks.`,
                type: parameterTypes.BUY_SELL_NUMBER_ARRAY,
                defaultValue: [1, 1],
                isSharedAmongSymbols: false
            })
        }
    },
    COG: {
        type: 'COG',
        name: 'COG',
        parameters: {
            COG_IN_FRONT_QTY: strategyParameterConfig({
                strategyType: 'COG',
                name: 'In Front Qty',
                description: `A pending order will be cancelled if the total quantity of orders with higher priority is larger than this specified value. A new order will be sent.`,
                type: parameterTypes.BUY_SELL_NUMBER_ARRAY,
                defaultValue: [0.4, 0.4],
                isSharedAmongSymbols: false
            }), 
            COG_MAX_CANCEL_TIMES_BEFORE_HIT: strategyParameterConfig({
                strategyType: 'COG',
                name: 'Max Cancel Times Before HIT',
                description: `Switch to HIT Strategy if the count of cancelling pending orders exceeds this specified value.`,
                type: parameterTypes.NUMBER,
                defaultValue: 4,
                isSharedAmongSymbols: false
            }),
            COG_ORDERBOOK_QTY_RATIO_THRESHOLD: strategyParameterConfig({
                strategyType: 'COG',
                name: 'Order Book Qty Ratio Threshold',
                description: `In the case of buying Hedge Instrument, order price should be <strong>Bid Price</strong> if 
                    <strong>Market Depth of Highest Bid</strong>/<strong>Market Depth of Lowest Ask</strong> is smaller than this speciefied value, otherwise order price should Bid Price + 1 Tick. It is similar for the selling case.`,
                type: parameterTypes.BUY_SELL_NUMBER_ARRAY,
                defaultValue: [0, 0],
                isSharedAmongSymbols: false
            }),
            COG_HIT_SEND_ON_MORE_AGGRESSIVE_TICKS: strategyParameterConfig({
                strategyType: 'COG',
                name: 'HIT Send On More Aggressive Ticks',
                description: `After the switch to HIT strategy, the order price will be Ask/Bid +/- this specified number of ticks when you buy/sell the Hedge Instrument.`,
                type: parameterTypes.NUMBER,
                defaultValue: 2,
                isSharedAmongSymbols: false
            }),
            COG_HIT_CANCEL_ON_MORE_PASSIVE_TICKS: strategyParameterConfig({
                strategyType: 'COG',
                name: 'HIT Cancel On More Passive Ticks',
                description: `After the switch to HIT strategy, a pending buy/sell order will be cancelled if current Ask/Bid is smaller/larger than the order price by this specified number of ticks.`,
                type: parameterTypes.NUMBER,
                defaultValue: 1,
                isSharedAmongSymbols: false
            })
        }
    },
    MAKE: {
        type: 'MAKE',
        name: 'MAKE',
        parameters: {
            MAKE_MAX_ALIVE_MS: strategyParameterConfig({
                strategyType: 'MAKE',
                name: 'Max Alive Ms',
                description: ``,
                type: parameterTypes.NUMBER,
                defaultValue: 1000,
                isSharedAmongSymbols: true
            }),
            MAKE_MAX_EXCHANGE_SPREAD_RATIO: strategyParameterConfig({
                strategyType: 'MAKE',
                name: 'Max Exchange Spread Ratio',
                description: ``,
                type: parameterTypes.NUMBER,
                defaultValue: 0,
                isSharedAmongSymbols: true
            }),
            MAKE_SPREAD_MAKER_MIN_SPREAD_RATIO: strategyParameterConfig({
                strategyType: 'MAKE',
                name: 'Spread Maker Min Spread Ratio',
                description: ``,
                type: parameterTypes.NUMBER,
                defaultValue: 0,
                isSharedAmongSymbols: true
            }),
            MAKE_SPREAD_MAKER_SPREAD_RATIO: strategyParameterConfig({
                strategyType: 'MAKE',
                name: 'Spread Maker Spread Ratio',
                description: ``,
                type: parameterTypes.NUMBER,
                defaultValue: 0,
                isSharedAmongSymbols: true
            }),
            MAKE_SPREAD_MAKER_QUANTITY: strategyParameterConfig({
                strategyType: 'MAKE',
                name: 'Spread Maker Quantity',
                description: ``,
                type: parameterTypes.NUMBER,
                defaultValue: 0,
                isSharedAmongSymbols: true
            }),
            MAKE_DEPTH_MAKER_MIN_SPREAD_RATIO: strategyParameterConfig({
                strategyType: 'MAKE',
                name: 'Depth Maker Min Spread Ratio',
                description: ``,
                type: parameterTypes.NUMBER,
                defaultValue: 0,
                isSharedAmongSymbols: true
            }),
            MAKE_DEPTH_MAKER_SPREAD_RATIO: strategyParameterConfig({
                strategyType: 'MAKE',
                name: 'Depth Maker Spread Ratio',
                description: ``,
                type: parameterTypes.NUMBER,
                defaultValue: 0,
                isSharedAmongSymbols: true
            })
        }
    },
    MMAKE: {
        type: 'MMAKE',
        name: 'MMAKE',
        parameters: {
            MMAKE_MAX_ALIVE_MS: strategyParameterConfig({
                strategyType: 'MMAKE',
                name: 'Max Alive Ms',
                description: ``,
                type: parameterTypes.NUMBER,
                defaultValue: 60000,
                isSharedAmongSymbols: true
            }),
            MMAKE_MAX_EXCHANGE_SPREAD: strategyParameterConfig({
                strategyType: 'MMAKE',
                name: 'Max Exchange Spread',
                description: ``,
                type: parameterTypes.NUMBER,
                defaultValue: 0,
                isSharedAmongSymbols: true
            }),
            MMAKE_MIN_SPREAD: strategyParameterConfig({
                strategyType: 'MMAKE',
                name: 'Min Spread',
                description: ``,
                type: parameterTypes.NUMBER,
                defaultValue: 0,
                isSharedAmongSymbols: true
            }),
            MMAKE_EXCHANGE_REQUIRED_SPREAD: strategyParameterConfig({
                strategyType: 'MAKE',
                name: 'Exchange Required Spread',
                description: ``,
                type: parameterTypes.NUMBER,
                defaultValue: 0,
                isSharedAmongSymbols: true
            }),
            MMAKE_SPREAD_MAKER_QUANTITY: strategyParameterConfig({
                strategyType: 'MMAKE',
                name: 'Spread Maker Quantity',
                description: ``,
                type: parameterTypes.NUMBER,
                defaultValue: 0,
                isSharedAmongSymbols: true
            }),
            MMAKE_DEPTH_MINUS_SPREAD_MAKER_SPREAD: strategyParameterConfig({
                strategyType: 'MMAKE',
                name: 'Depth Minus Spread Maker Spread',
                description: ``,
                type: parameterTypes.NUMBER,
                defaultValue: 0,
                isSharedAmongSymbols: true
            }),
            MMAKE_BASE_LEVEL_CHECK_ENABLED: strategyParameterConfig({
                strategyType: 'MMAKE',
                name: 'Base Level Check Enabled',
                description: ``,
                type: parameterTypes.BOOLEAN,
                defaultValue: false,
                isSharedAmongSymbols: true
            })
        }
    },
    // LOCKDIME: {
    //     type: 'LOCKDIME',
    //     name: 'LOCKDIME',
    //     parameters: {
    //         LOCKDIME_ORDER_COOLOFF_MS: strategyParameterConfig({
    //             strategyType: 'LOCKDIME',
    //             name: 'Order Cooloff MS',
    //             description: `This is the cool-off time between sending orders on the same side.`,
    //             type: parameterTypes.NUMBER,
    //             defaultValue: 100,
    //             isSharedAmongSymbols: false
    //         }),
    //         LOCKDIME_CALCSEC_COOLOFF_MS: strategyParameterConfig({
    //             strategyType: 'LOCKDIME',
    //             name: 'Calcsec Cooloff MS',
    //             description: `This is the cool-off time to update last executed notional value.`,
    //             type: parameterTypes.NUMBER,
    //             defaultValue: 100,
    //             isSharedAmongSymbols: false
    //         }),
    //         LOCKDIME_POS_EXPANDING_COOLOFF_MS: strategyParameterConfig({
    //             strategyType: 'LOCKDIME',
    //             name: 'Pos Expanding Cooloff MS',
    //             description: `This is the cool-off time between expanding positions.`,
    //             type: parameterTypes.NUMBER,
    //             defaultValue: 60000,
    //             isSharedAmongSymbols: false
    //         }),
    //         LOCKDIME_POS_EXIT_COOLOFF_MS: strategyParameterConfig({
    //             strategyType: 'LOCKDIME',
    //             name: 'Pos Exit Cooloff MS',
    //             description: `This is the cool-off time between exiting positions.`,
    //             type: parameterTypes.NUMBER,
    //             defaultValue: 60000,
    //             isSharedAmongSymbols: false
    //         }),
    //         LOCKDIME_ENTRY_LOCK_FLAG: strategyParameterConfig({
    //             strategyType: 'LOCKDIME',
    //             name: 'Entry Lock Flag',
    //             description: `This is the lock check flag for the case of expanding positions.`,
    //             type: parameterTypes.BOOLEAN,
    //             defaultValue: true,
    //             isSharedAmongSymbols: false
    //         }),
    //         LOCKDIME_EXIT_LOCK_FLAG: strategyParameterConfig({
    //             strategyType: 'LOCKDIME',
    //             name: 'Exit Lock Flag',
    //             description: `This is the lock check flag for the case of exiting positions.`,
    //             type: parameterTypes.BOOLEAN,
    //             defaultValue: true,
    //             isSharedAmongSymbols: false
    //         }),
    //         LOCKDIME_COOLOFF_MULTIPLIER: strategyParameterConfig({
    //             strategyType: 'LOCKDIME',
    //             name: 'Cooloff multiplier',
    //             description: `The order execution size multiplier filters small trades and prevent lock.`,
    //             type: parameterTypes.NUMBER,
    //             defaultValue: 0.9,
    //             isSharedAmongSymbols: false
    //         }),
    //         LOCKDIME_SAFE_CANCEL_FLAG: strategyParameterConfig({
    //             strategyType: 'LOCKDIME',
    //             name: 'Safe Cancel Flag',
    //             description: `If safe cancel flag is true, theory price will be capped by hedge price in <b>should cancel</b>.`,
    //             type: parameterTypes.BOOLEAN,
    //             defaultValue: true,
    //             isSharedAmongSymbols: false
    //         }),
    //         LOCKDIME_MODEL_CANCEL_FLAG: strategyParameterConfig({
    //             strategyType: 'LOCKDIME',
    //             name: 'Model Cancel Flag',
    //             description: `If model cancel flag is true, <b>should cancel</b> follows signal cancel logic, otherwise follows <b>dime cancel logic</b>.`,
    //             type: parameterTypes.BOOLEAN,
    //             defaultValue: false,
    //             isSharedAmongSymbols: false
    //         }),
    //         LOCKDIME_PROFIT_EDGE: strategyParameterConfig({
    //             strategyType: 'LOCKDIME',
    //             name: 'Profit Edge',
    //             description: `This is the proportion of edge before canceling an order. The smaller, the more aggressive.`,
    //             type: parameterTypes.NUMBER,
    //             defaultValue: 0.7,
    //             isSharedAmongSymbols: false
    //         }),
    //         LOCKDIME_UPDATE_QUOTE_ERROR: strategyParameterConfig({
    //             strategyType: 'LOCKDIME',
    //             name: 'Update Quote Error',
    //             description: `Cancel order if the difference between <b>qpx</b> and <b>opx</b> is greater than <b>Quote Error</b>`,
    //             type: parameterTypes.NUMBER,
    //             defaultValue: 0.0001,
    //             isSharedAmongSymbols: false
    //         }),
    //         LOCKDIME_AGGRESSIVE_TICK_MULTIPLIER: strategyParameterConfig({
    //             strategyType: 'LOCKDIME',
    //             name: 'Aggressive Tick Multiplier',
    //             description: ``,
    //             type: parameterTypes.NUMBER,
    //             defaultValue: 1,
    //             isSharedAmongSymbols: false
    //         })
    //     }
    // },
    DSWAP: {
        type: 'DSWAP',
        name: 'DSWAP',
        parameters: {
            DSWAP_MIN_QUANTITY: strategyParameterConfig({
                strategyType: 'DSWAP',
                name: 'Min Quantity',
                description: ``,
                type: parameterTypes.BUY_SELL_NUMBER_ARRAY,
                defaultValue: [0, 0],
                isSharedAmongSymbols: false
            }),
            DSWAP_SLIPPAGE: strategyParameterConfig({
                strategyType: 'DSWAP',
                name: 'Slippage',
                description: ``,
                type: parameterTypes.BUY_SELL_NUMBER_ARRAY,
                defaultValue: [0.002, 0.002],
                isSharedAmongSymbols: false
            }),
            DSWAP_GAS: strategyParameterConfig({
                strategyType: 'DSWAP',
                name: 'Gas',
                description: ``,
                type: parameterTypes.NUMBER,
                defaultValue: 250000,
                isSharedAmongSymbols: false
            }),
            DSWAP_GAS_PRICE_IN_GWEI: strategyParameterConfig({
                strategyType: 'DSWAP',
                name: 'Gas Price in Gwei',
                description: ``,
                type: parameterTypes.NUMBER,
                defaultValue: 20,
                isSharedAmongSymbols: false
            }),
            DSWAP_AUTO_ADJUST: strategyParameterConfig({
                strategyType: 'DSWAP',
                name: 'Auto Ajudst',
                description: ``,
                type: parameterTypes.BOOLEAN,
                defaultValue: 250000,
                isSharedAmongSymbols: false
            }),
            DSWAP_OBSERVATION_QTY: strategyParameterConfig({
                strategyType: 'DSWAP',
                name: 'Observation Qty',
                description: ``,
                type: parameterTypes.NUMBER,
                defaultValue: 0,
                isSharedAmongSymbols: false
            }),
            DSWAP_MAX_GAS_PRICE_IN_GWEI: strategyParameterConfig({
                strategyType: 'DSWAP',
                name: 'Max Gas Price in Gwei',
                description: ``,
                type: parameterTypes.NUMBER,
                defaultValue: 250,
                isSharedAmongSymbols: false
            }),
            DSWAP_ORDER_TS_TH: strategyParameterConfig({
                strategyType: 'DSWAP',
                name: 'Order TS TH',
                description: ``,
                type: parameterTypes.NUMBER,
                defaultValue: 2500,
                isSharedAmongSymbols: false
            })
        }
    },
    MANAGE: {
        type: 'MANAGE',
        name: 'MANAGE',
        parameters: {}
    }
}


// LOCKDIME should include paramters of DIME
// strategies.LOCKDIME.parameters = Object.assign({}, strategies.LOCKDIME.parameters, strategies.DIME.parameters)

export const legStrategyTypes = {
    leg1: [strategies.DIME.type, strategies.MULTILEVEL.type, strategies.OPTMULTILEVEL.type, strategies.HIT.type, strategies.COG.type, strategies.MAKE.type, strategies.MMAKE.type, strategies.DSWAP.type, strategies.MANAGE.type],
    leg2: [strategies.DIME.type, strategies.HIT.type, strategies.COG.type, strategies.MANAGE.type]
}

export const LOG_LEVELS = {
    INVALID: {
        value: 'INVALID',
        id: 0,
        disabled: true
    },
    DEBUG: {
        value: 'DEBUG',
        id: 1,
        disabled: false
    },
    INFO: {
        value: 'INFO',
        id: 2,
        disabled: false
    },
    WARNING: {
        value: 'WARNING',
        id: 3,
        disabled: true
    },
    FATAL: {
        value: 'FATAL',
        id: 4,
        disabled: true
    },
    USER1: {
        value: 'USER1',
        id: 5,
        disabled: false
    },
    USER2: {
        value: 'USER2',
        id: 6,
        disabled: false
    },
    USER3: {
        value: 'USER3',
        id: 7,
        disabled: false
    },
    USER4: {
        value: 'USER4',
        id: 8,
        disabled: false
    },
    USER5: {
        value: 'USER5',
        id: 9,
        disabled: false
    },
    USER6: {
        value: 'USER6',
        id: 10,
        disabled: false
    },
    USER7: {
        value: 'USER7',
        id: 11,
        disabled: false
    },
    USER8: {
        value: 'USER8',
        id: 12,
        disabled: false
    }
}

export const createProfileDefaultParameters = () => {
    return _.mapValues(profileParameters, 'defaultValue')
}

export const createLegDefaultParameters = (legNumber) => {
    return _.mapValues(legParameters[`leg${legNumber}`], 'defaultValue')
}

export const createSymbolDefaultParameters = (legNumber) => {
    return _.mapValues(symbolParameters[`leg${legNumber}`], 'defaultValue')
}

export const createRefSymbolDefaultParameters = () => {
    return _.mapValues(refSymbolParameters, 'defaultValue')
}

export const createStrategyDefaultSharedParameters = (type) => {
    const parameterConfigs = strategies[type].parameters
    return Object.keys(parameterConfigs).filter(key => parameterConfigs[key].isSharedAmongSymbols)
        .reduce((object, key) => {
            object[key] = parameterConfigs[key].defaultValue
            return object
        }, {})
}

export const createStrategyDefaultProductParameters = (type) => {
    const parameterConfigs = strategies[type].parameters
    return Object.keys(parameterConfigs).filter(key => !parameterConfigs[key].isSharedAmongSymbols)
        .reduce((object, key) => {
            object[key] = parameterConfigs[key].defaultValue
            return object
        }, {})
}

export const createLegSymbol = ({legNumber, symbolName, refSymbolName, strategyType}) => {
    const symbol = {
        name: symbolName || 'INVALID',
        params: createSymbolDefaultParameters(legNumber),
        strategyParams: createStrategyDefaultProductParameters(strategyType)
    }
    if (legNumber === 1) {
        symbol.refSymbol = refSymbolName || 'INVALID'
        symbol.refSymbols = [{
            name: refSymbolName || 'INVALID',
            params: createRefSymbolDefaultParameters()
        }]
    }
    return symbol
}

export const createDefaultProfile = ({leg1SymbolName, leg1RefSymbolName, leg1StrategyType, leg2SymbolName, leg2StrategyType}) => {
    leg1StrategyType = leg1StrategyType || legStrategyTypes['leg1'][0]
    leg2StrategyType = leg2StrategyType || legStrategyTypes['leg2'][0]
    return {
        name: '',
        params: createProfileDefaultParameters(),
        legs: {
            1: {
                params: createLegDefaultParameters(1),
                strategy: {
                    type: leg1StrategyType,
                    params: createStrategyDefaultSharedParameters(leg1StrategyType)
                },
                symbols: [createLegSymbol({
                    legNumber: 1,
                    symbolName: leg1SymbolName || null,
                    refSymbolName: leg1RefSymbolName || null,
                    strategyType: leg1StrategyType
                })]
            },
            2: {
                params: createLegDefaultParameters(2),
                strategy: {
                    type: leg2StrategyType,
                    params: createStrategyDefaultSharedParameters(leg2StrategyType)
                },
                symbols: [createLegSymbol({
                    legNumber: 2,
                    symbolName: leg2SymbolName || null,
                    strategyType: leg2StrategyType
                })]
            }
        },
        accounts: {}
    }
}

export const createTmpParameterConfig = (key, value) => {
    const isBoolean = (v) => {
        return _.isBoolean(v) || (_.isString(v) && (v.toLowerCase() === 'true' || v.toLowerCase() === 'false'))
    }
    let type = parameterTypes.NUMBER
    if (isBoolean(value)) {
        type = parameterTypes.BOOLEAN
    } else if (_.isString(value) && !_.isEmpty(value)) {
        type = parameterConfig.STRING
    } else if (_.isArray(value) && value.length > 1) {
        type = isBoolean(value[0]) ? parameterTypes.BUY_SELL_BOOLEAN_ARRAY : parameterTypes.BUY_SELL_NUMBER_ARRAY
    }
    return {
        category: 'CONFIG MISSING',
        name: key,
        type,
        defaultValue: value
    }
}

export const getStrategyParamConfig = (strategyType, key) => {
    const paramKey = _.startsWith(key, 'QUOTE_') || _.startsWith(key, 'HEDGE_') ? key.substring(6) : key
    return _.has(strategies, `${strategyType}.parameters.${paramKey}`) ? strategies[strategyType].parameters[paramKey] : null
}

export const getProfileLogLevels = (logLevelBits) => {
    return _.reduce(LOG_LEVELS, (result, _logLevel) => {
        if ((logLevelBits & (1 << _logLevel.id)) !== 0) {
            result.push(_logLevel.value)
        }
        return result
    }, [])
}